import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Steps, Button, message } from 'antd';
import DateSelection from '../../components/Booking/DateSelection/DateSelection';
import AddTickets from '../../components/Booking/AddTickets/AddTickets';
import AddMeals from '../../components/Booking/AddMeals/AddMeals';
import AddBilling from '../../components/Booking/AddBilling/AddBilling';
import AddMerchandise from '../../components/Booking/AddMerchandise/AddMerchandise';
import BookingHeader from '../../components/Booking/BookingHeader/BookingHeader';
import NewsAndUpdates from '../../components/homepage/NewsAndUpdates/NewsAndUpdates';
import BookingSummary from '../../components/Booking/BookingSummary/BookingSummary';
import './BookingPage.css';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import useCoupons from '../../hooks/common/useCoupon';
import useRetail from '../../hooks/useRetail';
import useAccountFlow from '../../hooks/common/useAccountFlow';

const steps = [
  { title: 'Select Date' },
  { title: 'Add Tickets' },
  { title: 'Add Meals' },
  { title: 'Add Merchandise' },
  { title: 'Add Info' }
];

const BookingPage = () => {
  const { getCouponById } = useCoupons();
  const { accountDetails } = useAccountFlow();
  const { createBooking, isBooking, createPaymentLink } = useRetail();
  const [bookingDate, setBookingDate] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [updatedBookingDetails, setUpdatedBookingDetails] = useState({});
  const [updatedFoodDetails, setUpdatedFoodDetails] = useState({});
  const [updatedMerchandise, setUpdatedMerchandise] = useState({});
  const [updatedBillingDetails, setUpdatedBillingDetails] = useState({});
  const [bookingSummaryData, setBookingSummaryData] = useState({});
  const [couponDetails, setCouponDetails] = useState();
  const [discountAmount, setDiscountAmount] = useState(0);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 575);
      setIsDesktop(window.innerWidth >= 991);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  const handleDateSelect = (date) => {
    setBookingDate(date);
    console.log(date)
  };

  // const next = () => {
  //   setCurrentStep((prev) => prev + 1);
  // };

  // const prev = () => {
  //   setCurrentStep((prev) => prev - 1);
  // };
  useEffect(() => {
    if (isMobile) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [currentStep]);
  
  const next = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };
  
  const prev = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };  

  const totalAmount =  
        (updatedBookingDetails?.ticketTotal || 0) + 
        (updatedFoodDetails?.foodTotal || 0) + 
        (updatedMerchandise?.merchandiseTotal || 0);
  
    useEffect(() => {
      if (updatedBookingDetails?.couponCode) {
        getCouponById(updatedBookingDetails.couponCode)
          .then((data) => {
            setCouponDetails(data);
    
            if (data?.percentage && data?.count) {
              const discount = Math.round((totalAmount * data.percentage) / 100);
              setDiscountAmount(discount);
            } else {
              setDiscountAmount(0);
            }
          })
          .catch(() => {
            setCouponDetails(null);
            setDiscountAmount(0);
          });
      }
    }, [updatedBookingDetails?.couponCode, totalAmount]); 

    const totalPayable = Math.round(totalAmount - discountAmount);  
    const gstAmount = Math.round((totalPayable * 18) / 118);
    const gstPercentage = 18
    const beforeTax =  (totalPayable / (1 + gstPercentage / 100)).toFixed(0);
    
    const isSubscription = accountDetails?.customer?.isSubscription ?? false;
    const isSubscriptionBooking = isSubscription ? true : false;

    const handleContinue = async () => {
      try {
        const fieldLabels = {
          fullName: "Full Name",
          phoneNumber: "Phone Number",
          email: "Email Address",
          pincode: "Pincode",
          bookingDate: "Booking Date",
          totalTicketCount: "Minimum 1 Ticket",
        };
    
        let missingFields = [];
    
        if (!updatedBillingDetails.fullName) missingFields.push(fieldLabels.fullName);
        if (!updatedBillingDetails.phoneNumber) missingFields.push(fieldLabels.phoneNumber);
        if (!updatedBillingDetails.email) missingFields.push(fieldLabels.email);
        if (!updatedBillingDetails.pincode) missingFields.push(fieldLabels.pincode);
        if (!bookingDate) missingFields.push(fieldLabels.bookingDate);
        if (!updatedBookingDetails.totalTicketCount || updatedBookingDetails.totalTicketCount <= 0) {
          missingFields.push(fieldLabels.totalTicketCount);
        }
    
        if (missingFields.length > 0) {
          message.error(`Please fill all required fields: ${missingFields.join(", ")}`);
          return;
        }

        if (couponDetails?.minTickets) {  
          const minTickets = couponDetails.minTickets;
          if (updatedBookingDetails.totalTicketCount < minTickets) {
            message.error(`This coupon requires a minimum of ${minTickets} tickets.`);
            return;
          }
        }        
    
        const bookingDetails = {
          bookingDate,
          adult: updatedBookingDetails.adult,
          child: updatedBookingDetails.child,
          senior: updatedBookingDetails.senior,
          disabled: updatedBookingDetails.disabled,
          totalTicketCount: updatedBookingDetails.totalTicketCount,
          totalAmount: updatedBookingDetails.ticketTotal,
          ticketPrices: updatedBookingDetails.ticketPrices,
          couponCode: updatedBookingDetails.couponCode,
          gstNo: updatedBookingDetails.gstNo,
    
          foodCount: updatedFoodDetails.foodCount,
          food: updatedFoodDetails.food,
          foodTotal: updatedFoodDetails.foodTotal,
    
          merchandise: updatedMerchandise.merchandise,
          merchandiseCount: updatedMerchandise.merchandiseCount,
          merchandiseTotal: updatedMerchandise.merchandiseTotal,
    
          fullName: updatedBillingDetails.fullName,
          phoneNumber: updatedBillingDetails.phoneNumber,
          email: updatedBillingDetails.email,
          pincode: updatedBillingDetails.pincode,
    
          gstAmount,
          discountAmount,
          beforeTax,
          isSubscriptionBooking,
          totalPayable,
        };
        console.log(bookingDetails)
    
        const response = await createBooking(bookingDetails);
    
        if (!response || !response._id) {
          message.error("Booking failed. Please try again.");
          return;
        }
    
        if (response?.payment_status === "success") {
          navigate(`/paymentsuccess/${response._id}`);
          return;
        }
    
        if (response?.payment_status !== "success") {
          const paymentLink = await createPaymentLink(response._id);
    
          if (paymentLink) {
            window.location.href = paymentLink;
          } else {
            message.error("Failed to create payment link.");
          }
        }
      } catch (error) {
        console.error("Booking Error:", error);
        message.error("Please fill all required fields correctly!");
      }
    };
  

  return (
    <>
      <BookingHeader />

      <Container className='pb-5'>
        <Row className='d-flex align-items-center justify-content-center'>
          <Col xxl={10} xl={10} lg={10} md={12} sm={12} xs={12}>
            <Steps
              current={currentStep}
              className="custom-steps"
              items={
                isMobile
                  ? [
                      {
                        title: (
                          <>
                            <p className="step-title mb-0 text-start">{steps[currentStep].title}</p>
                            <p className="step-count mb-2 text-start">Step {currentStep + 1}/{steps.length}</p>
                          </>
                        ),
                        icon: (
                          <span className="custom-step-icon fs-16 text-white">
                            {currentStep + 1}
                          </span>
                        ),
                      },
                    ]
                  : steps.map((step, index) => ({
                      title: step.title,
                      icon: (
                        <span className="custom-step-icon text-white">
                          {index + 1}
                        </span>
                      ),
                    }))
              }
            />

            <Row className='d-flex'>
              <Col xxl={7} xl={7} lg={7} md={12} sm={12} xs={12}>
                {currentStep === 0 && <DateSelection onDateSelect={handleDateSelect} />}
                {currentStep === 1 && (
                  <AddTickets 
                    setUpdatedBookingDetails={setUpdatedBookingDetails} 
                    bookingDate={bookingDate} 
                  />
                )}
                {currentStep === 2 && <AddMeals setUpdatedFoodDetails={setUpdatedFoodDetails} />}
                {currentStep === 3 && <AddMerchandise setUpdatedMerchandise={setUpdatedMerchandise} />}
                {currentStep === 4 && <AddBilling setUpdatedBillingDetails={setUpdatedBillingDetails} />}

                <div className='submit-web' style={{ marginTop: 24, display: 'flex', justifyContent: 'space-between' }}>
                  {currentStep > 0 && (
                    <Button 
                      type='default'
                      size='large'
                      className='fs-16 fw-700 booking-btn'
                      style={{borderColor: 'black'}}
                      onClick={prev} 
                    >
                      Back
                    </Button>
                  )}
                  {currentStep < steps.length - 1 ? (
                    <Button 
                      type="primary" 
                      size='large'
                      className="fs-16 fw-700 booking-btn"
                      onClick={next}
                    >
                      Continue
                      <span><ArrowRightOutlined/></span>
                    </Button>
                  ) : (
                    <Button 
                      type="primary" 
                      size='large'
                      className="fs-16 fw-700 booking-btn"
                      onClick={handleContinue}
                      loading={isBooking}
                    >
                        Proceed to Pay <span><ArrowRightOutlined/></span>                   
                    </Button>
                  )}
                </div>
              </Col>

              <Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12} className='booking-summary-container'>
                <BookingSummary
                  bookingDate={bookingDate}
                  updatedBookingDetails={updatedBookingDetails}
                  updatedFoodDetails={updatedFoodDetails}
                  updatedMerchandise={updatedMerchandise}
                  updatedBillingDetails={updatedBillingDetails}
                  bookingSummaryData={bookingSummaryData}
                />
              </Col>
            </Row>
            <Row className='submit-mob'>
              <Col md={6} sm={12} xs={12}>
                {currentStep > 0 && (
                  <Button 
                    type='default'
                    size='large'
                    className='fs-16 fw-700 booking-btn w-100 mt-3'
                    style={{borderColor: 'black'}}
                    onClick={prev} 
                  >
                    Back
                  </Button>
                )}
              </Col>
              <Col md={6} sm={12} xs={12}>
                {currentStep < steps.length - 1 ? (
                  <Button 
                    type="primary" 
                    size='large'
                    className="fs-16 fw-700 booking-btn w-100 mt-3"
                    onClick={next}
                  >
                    Continue
                    <span><ArrowRightOutlined/></span>
                  </Button>
                ) : (
                  <Button 
                    type="primary" 
                    size='large'
                    className="fs-16 fw-700 booking-btn w-100 mt-3"
                    onClick={handleContinue}
                    loading={isBooking}
                  >
                      Proceed to Pay <span><ArrowRightOutlined/></span>                   
                  </Button>
                )}
              </Col>
            </Row>
        </Col>
       </Row>
      </Container>
      
      <NewsAndUpdates />
    </>
  );
};

export default BookingPage;
