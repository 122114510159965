import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import destinations from '../../../common/Destination.json';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import './DestiBookingBanner.css'

const DestiBookingBanner = () => {
    const { id } = useParams();
    const destination = destinations.find((dest) => dest.id === parseInt(id));

    return (
        <div>
            <video
                className="banner-wrapper"
                autoPlay
                loop
                muted
                playsInline
                controls={false}
                disablePictureInPicture
                controlsList="nodownload noplaybackrate nofullscreen"
                style={{height: '360px'}}
            >
                <source 
                    src={require('../../../assets/videos/headervideo/header-video.mp4')} 
                    type="video/mp4"
                />
            </video>
      
            <div className="gradient-overlay">
                <Container className="py-5" >
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xxl={8} xl={8} lg={10} md={10} sm={12} xs={12} className="text-center">
                            <p className="fs-28 fw-400 sub-header text-black mb-0">
                                Explore Around the Waterpark 
                            </p>
                            <p className='fw-400 header'>
                                {destination?.title}
                            </p>
                            <p className='sub-heading-text fw-400 text-black'>
                                Customised Aqua Fun
                            </p>
                        </Col>
                    </Row>

                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='text-center'>
                            <Button
                                className="fs-16 fw-700 booking-btn text-white"
                                size="large"
                                style={{backgroundColor: 'black'}}
                            >
                                Gallery
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default DestiBookingBanner
