import { Button, Image, InputNumber } from 'antd';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './Transportation.css';
import Car from "../../../assets/images/custom-booking/transportation/img-car-rental.webp";
import SUV from "../../../assets/images/custom-booking/transportation/img-suv-rental.webp";
import Tempo from "../../../assets/images/custom-booking/transportation/img-tempo.webp";
import MiniBus from "../../../assets/images/custom-booking/transportation/img-mini-bus.webp";
import FullBus from "../../../assets/images/custom-booking/transportation/img-full-bus.webp";
import TourGuide from "../../../assets/images/custom-booking/transportation/img-tour-guide.webp";
import LifeGuard from "../../../assets/images/custom-booking/transportation/img-life-guard.webp";
import { CheckCircleFilled } from '@ant-design/icons';

const TransportOptions = [
    {
        id: "Small",
        title: "Small Groups (2-4 People):",
        groups: [
            { id: 1, name: "Car Rental", amount: "₹2500 per day", image: Car },
            { id: 2, name: "SUV Rental", amount: "₹3500 per day", image: SUV }
        ]
    },
    {
        id: "Medium",
        title: "Medium Groups (5-10 People):",
        groups: [{ id: 3, name: "Tempo Traveller", amount: "₹5000 per day (includes driver)", image: Tempo }]
    },
    {
        id: "Large",
        title: "Large Groups (11-20 People):",
        groups: [
            { id: 4, name: "Mini Bus Rental", amount: "₹7000 per day", image: MiniBus },
            { id: 5, name: "Full Bus (21-40 People)", amount: "₹9000 per day (includes driver)", image: FullBus }
        ]
    },
    {
        id: "Optional",
        title: "Optional Add-Ons:",
        groups: [
            { id: 6, name: "Tour Guide", amount: "₹2000 per day", image: TourGuide },
            { id: 7, name: "Life Guard (for water activities)", amount: "₹1500 per day", image: LifeGuard }
        ]
    }
];

const Transportation = ({ setCustomDetails, setActiveKey, customDetails }) => {
    const [selectedTransport, setSelectedTransport] = useState(customDetails.transportation || []);
    const [selectedOptional, setSelectedOptional] = useState(customDetails.optional || []);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 530);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 530);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        setCustomDetails(prevDetails => ({
            ...prevDetails,
            transportation: selectedTransport, 
            optional: selectedOptional
        }));
    }, [selectedTransport, selectedOptional, setCustomDetails]);

    const handleTransportSelect = (vehicle, quantity = 1) => {
        setSelectedTransport(prev => {
            const existing = prev.find(item => item.id === vehicle.id);
            if (existing) {
                return prev.filter(item => item.id !== vehicle.id);
            } else {
                return [...prev, { ...vehicle, quantity }];
            }
        });
    };
    
    const handleOptionalSelect = (vehicle, quantity = 1) => {
        setSelectedOptional(prev => {
            const existing = prev.find(item => item.id === vehicle.id);
            if (existing) {
                return prev.filter(item => item.id !== vehicle.id);
            } else {
                return [...prev, { ...vehicle, quantity }];
            }
        });
    };    

    const handleClick = () => {
        setActiveKey('6')
    }

    const handleBack = () => {
        setActiveKey('4')
    }

    return (
        <div className='p-2 custom-transport-container'>
            <h4 className='fs-24 fw-700 py-2'>Transportation Needs</h4>
            <p className='fs-16 fw-400 py-2'>Choose the Vehicle Based on Group Size</p>
            <Row>
                {TransportOptions.map(group => (
                    <Col key={group.id} xs={12} className="pb-4">
                        <h6 className="fs-18 fw-700">{group.title}</h6>
                        <Row>
                            {group.groups.map(vehicle => {
                                const isSelected =
                                    (group.id !== "Optional" && selectedTransport.some(item => item.id === vehicle.id)) ||
                                    (group.id === "Optional" && selectedOptional.some(item => item.id === vehicle.id));

                                return (
                                    <Col key={vehicle.id} xxl={6} xl={6} lg={12} md={12} sm={12} xs={12} className="pb-3">
                                        <div 
                                            className='p-3 d-flex gap-3 justify-content-between transport-option'
                                            style={{
                                                background: "#F8F8F9",
                                                borderRadius: '24px',
                                                border: isSelected ? "2px solid #000000" : "1px solid transparent",
                                            }}
                                            onClick={() => {
                                                if (group.id === "Optional") {
                                                    handleOptionalSelect(vehicle);
                                                } else {
                                                    handleTransportSelect(vehicle);
                                                }
                                            }}
                                        >
                                            <div className="d-flex flex-column">
                                                <CheckCircleFilled
                                                    className="check-circle"
                                                    style={{
                                                        color: isSelected ? "#2263E3" : "transparent",
                                                        border: isSelected ? "1px solid transparent" : "1px solid #303136",
                                                    }}
                                                />
                                                <p className="fs-14 fw-700 destination-name my-2">{vehicle.name}</p>
                                                <p className='fs-14 fw-400'>{vehicle.amount}</p>
                                                <InputNumber
                                                    min={1} 
                                                    max={20} 
                                                    defaultValue={1} 
                                                    onChange={(value) => {
                                                        if (group.id === "Optional") {
                                                            handleOptionalSelect(vehicle, value);
                                                        } else {
                                                            handleTransportSelect(vehicle, value);
                                                        }
                                                    }} 
                                                />
                                                
                                            </div>
                                            <div className="transport-image-container">
                                                <Image
                                                    src={vehicle.image}
                                                    alt={vehicle.name}
                                                    preview={false}
                                                    className="select-transport-img"
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Col>
                ))}
            </Row>

            <Row className='d-flex justify-content-end pt-4'>
                <Col xs={12} className='text-end'>
                    <Button 
                        type="default" 
                        className="fs-14 fw-600 custom-back-btn m-1" 
                        size="large" 
                        style={{borderColor: '#272727'}}
                        onClick={handleBack}
                    >
                        Back
                    </Button>
                    <Button 
                        type="primary" 
                        className="fs-14 fw-600 custom-book-btn m-1" 
                        size="large"
                        onClick={handleClick}
                    >
                        Continue
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default Transportation;
