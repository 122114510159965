import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './morevlogs.css';
import { Autoplay } from 'swiper/modules';
import { getSignedUrl } from '../../../services/s3';

const MoreVlogs = () => {
    const [videos, setVideos] = useState(null)
    const youtubeShortsIds = [
        'GiazLJTxUEs',
        '2DbDd9X47b8',
        'lMvMJZLn6WA',
        '6w6BDP-lk7M',
        'WlyGqqmz56I',
        '-wSuXZMzGBg'
    ];

    let keys = ["vlog/vlog1.mp4", "vlog/vlog2.mp4", "vlog/vlog3.mp4", "vlog/vlog4.mp4", "vlog/vlog5.mp4"]
    useEffect(() => {
        const fetchSignedUrls = async () => {
            try {
                const signedUrls = await Promise.all(keys.map(async (key) => {
                    const url = await getSignedUrl(key);
                    return url;
                }));
                setVideos(signedUrls)
            } catch (err) {
                console.log(err.message, err, "check this");
            }
        };
    
        fetchSignedUrls();
    }, []);

    return (
        <Container
            fluid={true}
            className='pb-5'
        >
            <Row className='align-items-center justify-content-center py-5'>
                <Col xxl={6} xl={6} lg={8} md={10} sm={12} xs={12} className='text-center'>
                    <p className='fs-28 mb-1 fw-400 text-black sub-header'>Visitor’s Vlogs </p>
                    <p className={`${window?.innerWidth > 834.98 ? 'fs-40' : window?.innerWidth <= 425 ? 'fs-28' : 'fs-36'} fw-800 text-black header mb-0`}>
                        Watch Our Enthusiastic Visitors Share Their Joyful Journeys!
                    </p>
                    <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black mb-0`}>
                        See thrilling moments, fun-filled rides, and the joy of spending time with loved ones, all through their eyes. 
                        Stay connected and get inspired for your own adventure—watch how our waterpark creates lasting memories for everyone!
                    </p>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Swiper
                        slidesPerView={3.5}
                        spaceBetween={13}
                        centeredSlides={true}
                        grabCursor={true}
                        mousewheel={true}
                        modules={[Autoplay]}
                        loop={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        className="more-vlogs"
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 13,
                            },
                            576: {
                                slidesPerView: 1.25,
                                spaceBetween: 13,
                            },
                            768: {
                                slidesPerView: 1.75,
                                spaceBetween: 15,
                            },
                            992: {
                                slidesPerView: 2,
                                spaceBetween: 13,
                            },
                            1024: {
                                slidesPerView: 2.25,
                                spaceBetween: 13,
                            },
                            1201: {
                                slidesPerView: 3,
                                spaceBetween: 13,
                            },
                            1400: {
                                slidesPerView: 3,
                                spaceBetween: 5,
                            },
                            1600: {
                                slidesPerView: 3.5,
                                spaceBetween: 30,
                            },
                            1920: {
                                slidesPerView: 5,
                                spaceBetween: 5,
                            },
                            2560: {
                                slidesPerView: 10,
                                spaceBetween: 10,
                            },
                        }}
                    >
                        {videos?.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div className='more-vlogs-video' style={{ borderRadius: '2rem' }}>
                                <video
                                    width={window?.innerWidth > 425 ? "409" : window?.innerWidth - 25}
                                    height="728"
                                    style={{
                                        borderRadius: 25,
                                        backgroundColor: "#",
                                        pointerEvents: "none",
                                    }}
                                    src={item}
                                    title={`YouTube video player - Slide ${index + 1}`}
                                    frameBorder="0"
                                    autoPlay
                                    muted
                                    loop
                                    className="slide-video pointer-event-none"
                                ></video>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Col>
            </Row>
        </Container>
    );
};

export default MoreVlogs;


