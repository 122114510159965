import React,{useState} from "react";
import "./VisiterPage.css";
import destinations from "../../../common/Destination.json";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Image, message, Modal } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";


const VisiterPage = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleBookingClick = (id) => {
    navigate(`/destinationbooking/${id}`);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleNavigate = () => {
    const token = localStorage.getItem("token");
    const customerDataString = localStorage.getItem('customerData'); 
    if (!token || !customerDataString) {
        message.error("You need to log in first.");
        navigate("/login");
        return;
    }

    let customerData;
    try {
        customerData = JSON.parse(customerDataString);
    } catch (error) {
        message.error('Invalid user data. Please log in again.');
        console.error('Error parsing customerData:', error);
        navigate('/login');
        return;
    }

    const { email, fullName, phone } = customerData;
    
    if (!email || !fullName || !phone) {
        message.error('Incomplete user details. Please login');
        navigate('/accountflow');
        return;
    }
    navigate('/custom-booking');
  }

  return (
    <Container fluid={false} className="py-5 top-destination">
      <Row>
        {destinations.map((destination) => (
          <Col 
            key={destination.id}
            xxl={destination.id === 1 ? 6 : 3}
            xl={destination.id === 1 ? 6 : 3}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="pb-4"
          >
            <div className="position-relative visit-img">
              <Image
                src={require(`../../../assets/images/topdestination/destinationimg/${destination.image}`)}
                className='visit-zoom'
                alt={"SharavatiRiver"}
                preview={false}
              />

              <Button
                type="default"
                className="fs-16 fw-700  btn-sharavati"
                size="large"
                style={{borderRadius: '28px'}}
                onClick={() => handleBookingClick(destination.id)}
              >
                {destination.name}
                <ArrowRightOutlined />
              </Button>
            </div>
          </Col>
        ))}

        <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={12} className="pb-4">
            <div className="position-relative">
                <div className="position-relative desti-custom">
                    <p className={`${window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'} fw-700 mx-4 pt-4`}>
                      "Tailor Your Joy: Custom Experience Awaits!"
                    </p>
                    <p className="mx-4 fs-16 fw-400">
                      Looking for a personalized adventure? Reach out today to craft a plan that matches your vibe. Call us now to start personalizing your unforgettable fun!
                    </p>
                    <Button
                        type="default"
                        className="fs-16 fw-700 custom-btn"
                        size="large"
                        style={{background: '#ffff'}}
                        onClick={handleNavigate}
                    >
                        Custom
                        <ArrowRightOutlined />
                    </Button>
                </div>
            </div>
        </Col>
      </Row>

      <Modal
         visible={isModalVisible}
         onOk={handleModalOk}
         centered
         onCancel={handleModalCancel}
         okText="Okay"
         footer={[
           <Button key="submit" type="primary" onClick={handleModalOk}>
             Okay
           </Button>
         ]}
      >
        <p className="fw-700 fs-18 mx-4">
          "Tailor Your Joy: Custom Experience Awaits!"
        </p>
        <p>Looking for a personalized adventure? Reach out today to craft a plan that matches your vibe. Call us now to start personalizing your unforgettable fun!</p>
        <p>To make customized booking please contact with our experts</p>
        <p>Contact us: <span className="fw-600 mx-4">+91-9739777566</span> <span className="fw-600 mb-1">joynjoyy2024@gmail.com</span></p>
      </Modal>
    </Container>
  );
};

export default VisiterPage;
