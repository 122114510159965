import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Modal, Space, Collapse } from 'antd';
import './BookingSummary.css';
import useCoupons from '../../../hooks/common/useCoupon';

const BookingSummary = ({ bookingDate, updatedBookingDetails = {}, updatedFoodDetails = {}, updatedMerchandise = {} }) => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { getCouponById } = useCoupons();
  const [couponDetails, setCouponDetails] = useState();
  const [discountAmount, setDiscountAmount] = useState(0);

  const handleLogin = () => {
    setIsModalVisible(false);
    navigate('/login');
  };

  useEffect(() => {
    if (updatedBookingDetails.couponCode) {
      getCouponById(updatedBookingDetails.couponCode)
        .then((data) => {
          setCouponDetails(data);
        })
        .catch((error) => {
          console.error("Error fetching coupon details:", error);
        });
    }
  }, [updatedBookingDetails.couponCode]);

  const totalAmount =  
      (updatedBookingDetails?.ticketTotal || 0) + 
      (updatedFoodDetails?.foodTotal || 0) + 
      (updatedMerchandise?.merchandiseTotal || 0);

  const ticketTotal = (updatedBookingDetails?.ticketTotal || 0);
  
  // useEffect(() => {
  //   if (updatedBookingDetails?.couponCode) {
  //     getCouponById(updatedBookingDetails.couponCode)
  //       .then((data) => {
  //         setCouponDetails(data);
  
  //         if (data?.percentage && data?.count) {
  //           const discount = Math.round((totalAmount * data.percentage) / 100);
  //           setDiscountAmount(discount);
  //         } else {
  //           setDiscountAmount(0);
  //         }
  //       })
  //       .catch(() => {
  //         setCouponDetails(null);
  //         setDiscountAmount(0);
  //       });
  //   }
  // }, [updatedBookingDetails?.couponCode, totalAmount]);  

  useEffect(() => {
    if (updatedBookingDetails?.couponCode) {
      getCouponById(updatedBookingDetails.couponCode)
        .then((data) => {
          setCouponDetails(data);
  
          const minTickets = data?.minTickets ?? 0;
          const totalTickets = updatedBookingDetails?.totalTicketCount || 0;
  
          if (minTickets === 0 || totalTickets >= minTickets) {
            const discount = Math.round((totalAmount * (data?.percentage || 0)) / 100);
            setDiscountAmount(discount);
          } else {
            setDiscountAmount(0);
          }
        })
        .catch(() => {
          setCouponDetails(null);
          setDiscountAmount(0);
        });
    } else {
      setDiscountAmount(0);
    }
  }, [updatedBookingDetails?.couponCode, updatedBookingDetails?.totalTicketCount, totalAmount]);    
  
  let totalPayable = (totalAmount - discountAmount);
  // const gstAmount = ((totalPayable * 18) / 118);
  const gstAmount = ((ticketTotal * 18) / 118);
  totalPayable = (totalPayable + gstAmount).toFixed(2);
  // const gstPercentage = 18
  // const beforeTax =  (totalPayable / (1 + gstPercentage / 100)).toFixed(2);
  const beforeTax =  totalAmount.toFixed(2);

  return (
    <div 
      className='booking-summary-container p-4'
      style={{ background: '#F8F8F9', borderRadius: '15px' }}
    >
      <h4 className='fs-28 fw-700'>Booking Summary</h4>
      <div 
        className='d-flex justify-content-between align-items-center text-center select-date mt-3 py-2'
        style={{background: '#FFFFFF', borderRadius: '10px'}}  
      >
        <h5 className='fs-18 fw-700 mb-0'>Date:</h5>
        <p className='fs-18 fw-700 mb-0'>{bookingDate || updatedBookingDetails.bookingDate || 'None'}</p>
      </div>

      <div>
        {updatedBookingDetails.couponCode && couponDetails &&  couponDetails.count === true && (
          <Space direction="vertical" className="mt-3 w-100">
            <Collapse 
              style={{ backgroundColor: 'white' }}
            >
              <Collapse.Panel 
                key="1" 
                header={<h5 className="fs-18 fw-700 m-0">{couponDetails.offerName}</h5>}
                style={{ backgroundColor: 'white' }}
              >
                <div className='fs-16 fw-400' dangerouslySetInnerHTML={{ __html: couponDetails.description }}></div>
              </Collapse.Panel>
            </Collapse>
          </Space>
        )}
      </div>

      <Row className=' py-3 border-bottom'>
        <Col span={24}>
          <div className='d-flex justify-content-between align-items-center'>
            <h5 className='fs-18 fw-700'>Ticket details ({updatedBookingDetails.totalTicketCount || 0})</h5>
            <span className='fs-18 fw-700'>₹{(updatedBookingDetails.ticketTotal || 0).toFixed(2)}</span>
          </div>

          {["adult", "child", "senior", "disabled"].some(type => updatedBookingDetails[type]) ? (
            ["adult", "child", "senior", "disabled"].map((type) => (
              updatedBookingDetails[type] ? (
                <Row className='my-2' key={type}>
                  <Col span={24}>
                    <p className='fs-16 fw-700 mb-0'>{type.charAt(0).toUpperCase() + type.slice(1)}</p>
                    <div className='d-flex justify-content-between align-items-center'>
                      <h5 className='fs-14 fw-600'>
                        ₹{(updatedBookingDetails.ticketPrices?.[type].toFixed(2))} x {updatedBookingDetails[type] || 0}
                      </h5>
                      <h5 className='fs-16 fw-600' style={{ color: '#60616C' }}>
                        ₹{updatedBookingDetails[`${type}TotalPrice`].toFixed(2)}
                      </h5>
                    </div>
                  </Col>
                </Row>
              ) : null
            ))
            ) : (
              <h6 className='fs-14 fw-600 mb-0'>None</h6>
          )}
        </Col>
      </Row>

      <Row className='py-3 border-bottom'>
        <Col span={24}>
          <div className='d-flex justify-content-between align-items-center'>
            <h5 className='fs-18 fw-700'>Meals details ({updatedFoodDetails.foodCount || 0})</h5>
            <span className='fs-18 fw-700'>₹{(updatedFoodDetails.foodTotal || 0).toFixed(2)}</span>
          </div>
          {updatedFoodDetails.food?.length > 0 ? (
            updatedFoodDetails.food.map((foodItem, index) => (
              <Row className='my-2' key={index}>
                <Col span={24}>
                  <p className='fs-16 fw-700 mb-0'>{foodItem.foodName}</p>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h5 className='fs-14 fw-600'>₹{foodItem.price.toFixed(2)} x {foodItem.count}</h5>
                    <h5 className='fs-16 fw-600' style={{ color: '#60616C' }}>₹{foodItem.totalPrice.toFixed(2)}</h5>
                  </div>
                </Col>
              </Row>
            ))
          ) : (
            <h6 className='fs-14 fw-600 mb-0'>None</h6>
          )}
        </Col>
      </Row>

      <Row className='py-3 border-bottom'>
        <Col span={24}>
          <div className='d-flex justify-content-between align-items-center'>
            <h5 className='fs-18 fw-700'>Merchandise details ({updatedMerchandise.merchandiseCount || 0})</h5>
            <span className='fs-18 fw-700'>₹{(updatedMerchandise.merchandiseTotal || 0).toFixed(2)}</span>
          </div>
          {updatedMerchandise.merchandise?.length > 0 ? (
            updatedMerchandise.merchandise.map((merchItem, index) => (
              <Row className='my-2' key={index}>
                <Col span={24}>
                  <p className='fs-16 fw-700 mb-0'>{merchItem.merchandiseName} {merchItem.size ? `(Size: ${merchItem.size})` : ''}</p>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h5 className='fs-14 fw-600'>₹{merchItem.price.toFixed(2)} x {merchItem.count}</h5>
                    <h5 className='fs-16 fw-600' style={{ color: '#60616C' }}>₹{(merchItem.totalPrice || 0).toFixed(2)}</h5>
                  </div>
                </Col>
              </Row>
            ))
          ) : (
            <h6 className='fs-14 fw-600 mb-0'>None</h6>
          )}
        </Col>
      </Row>

      <Row className='pt-4'>
        <Col span={24}>
          <div className='d-flex justify-content-between align-items-center'>
            <p className='fs-16 fw-600 mb-1'>Total Amount:</p>
            <span className='fs-16 fw-600 mb-1'>₹{totalAmount.toFixed(2) || 0}</span>
          </div>
          <div className='d-flex justify-content-between align-items-center'>
            <p className='fs-16 fw-600 mb-1'>Discount:</p>
            <span className='fs-16 fw-600 mb-1'>₹{discountAmount.toFixed(2) || 0}</span>
          </div>
          <div className='d-flex justify-content-between'>
            <p className='fs-16 fw-600 mb-1'>Gst Amount:</p>
            <span className='fs-16 fw-600 mb-1'>₹{gstAmount.toFixed(2) || 0}</span>
          </div>
          <div className='d-flex justify-content-between'>
            <p className='fs-16 fw-600'>Total Amount Before Tax:</p>
            <span className='fs-16 fw-600'>₹{beforeTax || 0}</span>
          </div>
          <div className='d-flex justify-content-between align-items-center border-top pt-3'>
            <h5 className='fs-18 fw-700'>Total amount to be paid:</h5>
            <span className='fs-18 fw-700'>₹{totalPayable || 0}</span>
          </div>
        </Col>
      </Row>

      <Modal visible={isModalVisible} onCancel={() => setIsModalVisible(false)} footer={null}>
        <Row className='d-flex align-items-center justify-content-center py-3'>
          <Col className='text-center'>
            <h5>Login to book more tickets</h5>
            <p>A maximum of 20 tickets are allowed per transaction.</p>
            <Button type='primary' onClick={handleLogin}>Login</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default BookingSummary;
