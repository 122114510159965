import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form, Button, Input, Select, Checkbox, DatePicker, message } from 'antd';
import './PersonalPage.css';
import useAccountFlow from '../../../hooks/common/useAccountFlow';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
import useSignUp from '../../../hooks/common/useSignUp';

const { Option } = Select;

const PersonalPage = () => {
  const navigate = useNavigate();
  const { resendOtp } = useSignUp()
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [date, setDate] = useState(null);
  const [dateFocused, setDateFocused] = useState(false);

  const { accountDetails, form, updateProfile } = useAccountFlow()

    useEffect(() => {
      if (accountDetails && accountDetails.customer) {
        form.setFieldsValue({
          fullName: accountDetails.customer?.fullName || "",
          phone: accountDetails.customer?.phone || "",
          email: accountDetails.customer?.email || "",
          gender: accountDetails.customer?.gender || "",
          dob: accountDetails.customer?.dob ? dayjs(accountDetails?.customer?.dob, "DD-MM-YYYY") : null,
          promotions: accountDetails.customer?.promotions || false,
          city: accountDetails.customer?.address?.city || "",
          address_1: accountDetails.customer?.address?.address_1 || "",
          address_2: accountDetails.customer?.address?.address_2 || "",
          zipCode: accountDetails.customer?.address?.zipCode || "",
          state: accountDetails.customer?.address?.state || "",
          country: accountDetails.customer?.address?.country || "",
          customerType: accountDetails.customer?.customerType || ""
        });

        setDate(accountDetails?.customer?.dob ? dayjs(accountDetails?.customer?.dob, "DD-MM-YYYY") : null);
        if (!accountDetails.customer?.phone) {
          form.setFields([
            {
              name: "phone",
              errors: ["Phone Number is required"],
            }
          ]);
          message.error("Enter your phone number & verify OTP.");
        }
      }
  }, [accountDetails, form]);

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailErrorMessage(emailRegex.test(value) ? '' : 'Please enter a valid email ID');
  };

  const onFinish = (values) => {
    if(!accountDetails?.customer?.phone) {
      const userId = accountDetails?.customer?._id.toString();
      console.log(userId, "CHECK THI SHTHIS")
      const submissionData = {
        ...values,
        gender: values.gender ? values.gender.toLowerCase() : undefined,
      };
      updateProfile(submissionData);
      const payload = {
        _id: userId, 
        phone: values?.phone
      }
      resendOtp(payload)
      navigate("/verify-otp", {state: {userId}} );
    } else {
      const submissionData = {
        ...values,
        gender: values.gender ? values.gender.toLowerCase() : undefined,
      };
      updateProfile(submissionData);
    }
  };

  return (
    <Container fluid={false} className="personal-container">
      <Row className="justify-content-center mb-4">
        <Col xxl={10} xl={10} lg={12} md={12} sm={12} xs={12}>
          <h3 className="fs-32 fw-700">Personal Info</h3>
          
          <Form 
            form={form} 
            layout="vertical" 
            className="py-4" 
            onFinish={onFinish}
          >
            <Row>
              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="floating-form-item">
                  <Form.Item
                    label={"Full Name"}
                    name="fullName"
                    rules={[{ required: true, message: 'Please enter your Full name' }]}
                  >
                    <Input className="floating-input fs-14 fw-400" placeholder="First Name" />
                  </Form.Item>
                </div>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="floating-form-item">
                  <Form.Item
                    label={"Phone Number"}
                    name="phone"
                    rules={[{ required: true, message: 'Please enter your Phone number' }]}
                  >
                    <Input
                      className="floating-input-phone fs-14 fw-400"
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      bordered={false}
                      addonBefore="+91"
                      disabled={accountDetails?.phone}
                    />
                  </Form.Item>
                </div>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="floating-form-item">
                  <Form.Item
                    label={"Email"}
                    name="email"
                    rules={[{ required: true, message: 'Please enter your Email ID' }]}
                  >
                    <Input
                      className="floating-input fs-14 fw-400 text-black"
                      type="email"
                      placeholder="Email Id"
                      value={email}
                      onChange={handleEmailChange}
                      disabled
                      // disabled={accountDetails?.email}
                    />
                  </Form.Item>
                  {emailErrorMessage && <div className="text-danger">{emailErrorMessage}</div>}
                </div>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="floating-form-item">
                  <Form.Item
                    label={"Gender"}
                    name="gender"
                  >
                    <Select
                      className="floating-input fs-14 fw-400"
                      style={{ padding: '23px' }}
                      placeholder="Select Gender"
                      bordered={false}
                    >
                      <Option value="male">Male</Option>
                      <Option value="female">Female</Option>
                      <Option value="other">Other</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className={`floating-form-item ${date || dateFocused ? 'focused' : ''}`}>
                  <Form.Item
                    label={"Date Of Birth"}
                    name="dob"
                  >
                    <DatePicker
                      className="floating-input fs-14 fw-400"
                      format="DD-MM-YYYY"
                      placeholder="DD-MM-YYYY"
                      value={date}
                      onChange={(value) => setDate(value)}
                      onFocus={() => setDateFocused(true)}
                      onBlur={() => setDateFocused(false)}
                    />
                  </Form.Item>
                </div>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="floating-form-item">
                  <Form.Item
                    label={"Address 1"}
                    name="address_1"
                  >
                    <Input className="floating-input fs-14 fw-400" placeholder="Address Line 1" />
                  </Form.Item>
                </div>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="floating-form-item">
                  <Form.Item
                    label={"Address 2"}
                    name="address_2"
                  >
                    <Input className="floating-input fs-14 fw-400" placeholder="Address Line 2" />
                  </Form.Item>
                </div>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="floating-form-item">
                  <Form.Item
                    label={"Zip Code"}
                    name="zipCode"
                  >
                    <Input className="floating-input fs-14 fw-400" placeholder="Zip/Postal code" />
                  </Form.Item>
                </div>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="floating-form-item">
                  <Form.Item
                    label={"City"}
                    name="city"
                  >
                    <Input className="floating-input fs-14 fw-400" placeholder="City" />
                  </Form.Item>
                </div>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="floating-form-item">
                  <Form.Item
                    label={"State"}
                    name="state"
                  >
                    <Input className="floating-input fs-14 fw-400" placeholder="State/Province/Region" />
                  </Form.Item>
                </div>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="floating-form-item">
                  <Form.Item
                    label={"Country"}
                    name="country"
                  >
                    <Input className="floating-input fs-14 fw-400" placeholder="Country" />
                  </Form.Item>
                </div>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="floating-form-item">
                  <Form.Item
                    label={"Group Type"}
                    name="customerType"
                    rules={[{ required: true, message: 'Please enter Group Type' }]}
                  >
                    <Select
                      className="floating-input fs-14 fw-400"
                      style={{ padding: '23px' }}
                      placeholder="Select Group Type"
                      bordered={false}
                    >
                      <Option value="individual">Individual</Option>
                      <Option value="bulkbooking">Bulk Booking</Option>
                      <Option value="college">College</Option>
                      <Option value="corporate">Corporate</Option>
                      <Option value="institution">Institution</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>

              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <p className="checkbox-header">I'd like to receive updates about offers and promotions through</p>
                <Form.Item
                  label={"Promotions"}
                  name="promotions"
                >
                  {/* <Checkbox.Group> */}
                    {/* <Checkbox value="email">Email</Checkbox> */}
                    <Checkbox defaultChecked={true} value="phone">Phone</Checkbox>
                  {/* </Checkbox.Group> */}
                </Form.Item>
              </Col>
            </Row>
            <Button
              type="primary"
              className=" p-4 button"
              style={{ borderRadius: '25px', marginTop: '1.5rem' }}
              htmlType="submit"
            >
              Update
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default PersonalPage;
