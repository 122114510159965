import React, { useEffect, useState } from 'react';
import { Col, Row } from "react-bootstrap";
import { Button, Image, message } from 'antd';
import { HolderOutlined } from '@ant-design/icons';
import './PlanYourItinerary.css';
import { format, parse } from 'date-fns';

const PlanYourItinerary = ({ setCustomDetails, customDetails, setActiveKey }) => {
    const [planItinerary, setPlanItinerary] = useState(customDetails.destination || []);
    const [itineraryPlan, setItineraryPlan] = useState(customDetails.itineraryPlan || []);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 530);
    const [draggedIndex, setDraggedIndex] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 530);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleDragStart = (draggedId) => (e) => {
        e.dataTransfer.setData("text/plain", draggedId);
        setDraggedIndex(draggedId);
        e.dataTransfer.effectAllowed = "move";
    };
    
    const handleDrop = (dropId) => (e) => {
        e.preventDefault();
        const draggedId = parseInt(e.dataTransfer.getData("text/plain"), 10);
        if (draggedId === dropId) return;
    
        const updatedList = [...planItinerary];
        const draggedItemIndex = updatedList.findIndex(item => item.id === draggedId);
        const draggedItem = updatedList[draggedItemIndex];
    
        updatedList.splice(draggedItemIndex, 1);
    
        const dropIndex = updatedList.findIndex(item => item.id === dropId);
        updatedList.splice(dropIndex, 0, draggedItem);
    
        updatedList.sort((a, b) => new Date(a.date) - new Date(b.date));

        setPlanItinerary(updatedList);
        setCustomDetails((prevDetails) => ({
            ...prevDetails,
            destination: updatedList,
        }));
    };    

    const parseDate = (dateString) => {
        return parse(dateString, 'dd MMM yyyy, EEEE', new Date());
    };

    const getDateRange = (startDate, endDate) => {
        const dateArray = [];
        let currentDate = parseDate(startDate);

        if (isNaN(currentDate)) return [];
        const end = parseDate(endDate);
        if (isNaN(end)) return [];

        while (currentDate <= end) {
            dateArray.push(format(currentDate, 'dd MMM yyyy, EEEE'));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dateArray;
    };

    const dateRange = customDetails.date
        ? [customDetails.date]
        : (customDetails.startDate && customDetails.endDate)
            ? getDateRange(customDetails.startDate, customDetails.endDate)
            : [];

    const groupDestinations = (destinations, numDays) => {
        const grouped = Array.from({ length: numDays }, () => []);
        let index = 0;
    
        for (let i = 0; i < Math.min(destinations.length, numDays); i++) {
            grouped[i].push(destinations[index++]);
        }
    
        let dayIndex = 0;
        while (index < destinations.length) {
            grouped[dayIndex % numDays].push(destinations[index++]);
            dayIndex++;
        }
    
        return grouped;
    };
    
    const groupedDestinations = groupDestinations(customDetails.destination, dateRange.length);
    

    const handleClick = () => {
        if (planItinerary.length === 0) {
            message.error("Please select at least one destination.");
            return;
        }

        const updatedItineraryPlan = dateRange.map((date, index) => ({
            date: date,
            name: groupedDestinations[index]?.map(destination => destination.name) || [],
        }));

        setItineraryPlan(updatedItineraryPlan);
        setCustomDetails((prevDetails) => ({
            ...prevDetails,
            itineraryPlan: updatedItineraryPlan,
        }));

        setActiveKey("7");
    };

    const handleBack = () => {
        setActiveKey('5');
    };

    return (
        <div className='select-plan-container'>
            <h4 className='fs-24 fw-700 py-2 pb-4'>Plan Your Itinerary</h4>

            {dateRange.map((date, dateIndex) => (
                <div key={dateIndex}>
                    <p className="fs-16 fw-700 pb-2">{date}</p>
                    <Row>
                        {groupedDestinations[dateIndex]?.map((destination) => (
                            <Col key={destination.id} xxl={6} xl={6} lg={12} md={12} sm={12} xs={12} className="pb-4">
                                <div
                                    className="p-3 d-flex gap-3 h-100"
                                    style={{
                                        background: "#F8F8F9",
                                        borderRadius: "24px",
                                        border: planItinerary.some(dest => dest.id === destination.id)
                                            ? "2px solid #000000"
                                            : "1px solid transparent",
                                    }}
                                    draggable="true"
                                    onDragStart={handleDragStart(destination.id)}
                                    onDragOver={(e) => e.preventDefault()}
                                    onDrop={handleDrop(destination.id)}
                                >
                                    <div className="d-flex flex-column align-items-start">
                                        <HolderOutlined style={{ color: "#2263E3", cursor: "grab", fontSize: "20px" }} />
                                        <p className="fs-14 fw-700 destination-name my-2">{destination.name}</p>
                                        <p className="fs-14 fw-400 desti-description">{destination.about}</p>
                                    </div>

                                    <div>
                                        <Image
                                            src={require(`../../../assets/images/topdestination/destinationimg/${destination.image}`)}
                                            alt={destination.name}
                                            preview={false}
                                            className="select-desti-img"
                                        />
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            ))}

            <Row className='d-flex justify-content-end pt-4'>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='text-end'>
                    <Button
                        type="default"
                        className="fs-14 fw-600 custom-back-btn m-1"
                        size="large"
                        style={{ borderColor: '#272727' }}
                        onClick={handleBack}
                    >
                        Back
                    </Button>

                    <Button
                        type="primary"
                        className="fs-14 fw-600 custom-book-btn m-1"
                        size="large"
                        onClick={handleClick}
                    >
                        Continue
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default PlanYourItinerary;
