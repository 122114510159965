import React, { useEffect, useState } from 'react';
import { Form, Input, Image, Modal, Button, message } from 'antd';
import { Row, Col, Container } from 'react-bootstrap';
import userIcon from '../../../assets/images/bookingimages/ticketicon.png';
import './AddTickets.css';
import FormItem from 'antd/es/form/FormItem';
import useRetail from '../../../hooks/useRetail';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useData } from '../../../context/DataContext';
import { useNavigate } from 'react-router-dom';
import useCoupons from '../../../hooks/common/useCoupon';
import useAccountFlow from '../../../hooks/common/useAccountFlow';
import { useBookingData } from '../../../context/BookingDataContext';

const ticketData = [
    {
        id: 1,
        type: "Adults",
        description: "Height above 140 cm or 4 feet 7 inches.",
        count: 0,
        price: 0,
    },
    {
        id: 2,
        type: "Children",
        description: "Height between 90 cm and 140 cm or 3 feet and 4 feet 7 inches.",
        count: 0,
        price: 0,
    },
    {
        id: 3,
        type: "Senior Citizen",
        description: "Aged above 60 years. Seniors required to show proof of age.",
        count: 0,
        price: 0,
    },
    {
        id: 4,
        type: "Disabled",
        description: "For individuals with a permanent disability.",
        count: 0,
        price: 0,
    }
];

const AddTickets = ({ setUpdatedBookingDetails, bookingDate }) => {
    const { priceMeta } = useRetail();
    const { getCouponById } = useCoupons();
    const { sharedData } = useData();
    const { setBookingData, bookingData } = useBookingData()
    const { accountDetails, handleAccount } = useAccountFlow();
    const [couponDetails, setCouponDetails] = useState(null);
    const [debouncedCoupon, setDebouncedCoupon] = useState('');
    const [gstNo, setGstNo] = useState(bookingData.updatedBookingDetails?.gstNo || '')
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [couponCode, setCouponCode] = useState(sharedData?.couponCode || bookingData.updatedBookingDetails?.couponCode || '');
    const [tickets, setTickets] = useState(() => {
        return bookingData.updatedBookingDetails?.tickets || ticketData;
    });
    const navigate = useNavigate();

    const isSubscription = accountDetails?.customer?.isSubscription ?? false;
    const isOtpVerified = accountDetails?.customer?.isOtpVerified ?? false;

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedCoupon(couponCode);
        }, 500);

        return () => clearTimeout(handler);
    }, [couponCode]);

    useEffect(() => {
        setCouponCode(isSubscription ? '' : (bookingData.updatedBookingDetails?.couponCode || sharedData?.couponCode || ''));
    }, [isSubscription, sharedData?.couponCode, bookingData.updatedBookingDetails?.couponCode]);    

    useEffect(() => {
        if (debouncedCoupon) {
            getCouponById(debouncedCoupon)
                .then((data) => setCouponDetails(data))
                .catch(() => message.error("Invalid coupon code"));
        }
    }, [debouncedCoupon]);

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('/').map(Number);
        
        const fullYear = year < 100 ? 2000 + year : year;
        const date = new Date(fullYear, month - 1, day);
    
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    };
    
    const isWeekend = (dateString) => {
        const date = parseDate(dateString);
        const day = date.getDay();

        return day === 0 || day === 6;
    };

    useEffect(() => {
        if (priceMeta) {
            const isWeekendDay = isWeekend(bookingDate);
            const priceType = isWeekendDay ? 'weekendPrice' : 'weekdaysPrice';

            setTickets((prevTickets) =>
                prevTickets.map((ticket) => {
                    let price = 0;
                    switch (ticket.type) {
                        case 'Adults':
                            price = priceMeta[priceType].adult;
                            break;
                        case 'Children':
                            price = priceMeta[priceType].child;
                            break;
                        case 'Senior Citizen':
                            price = priceMeta[priceType].senior;
                            break;
                        case 'Disabled':
                            price = priceMeta[priceType].disabled;
                            break;
                        default:
                            break;
                    }
                    return { ...ticket, price: price || 0 };
                })
            );
        }
    }, [priceMeta, bookingDate]);
    

    const totalTicketCount = tickets.reduce((sum, ticket) => sum + ticket.count, 0);
    const ticketTotal = tickets.reduce((total, ticket) => total + (ticket.price * ticket.count), 0);

    const getTotalPrice = (type) => {
        const ticket = tickets.find(ticket => ticket.type === type);
        return ticket ? ticket.count * ticket.price : 0;
    };

    const adultTotalPrice = getTotalPrice('Adults');
    const childTotalPrice = getTotalPrice('Children');
    const seniorTotalPrice = getTotalPrice('Senior Citizen');
    const disabledTotalPrice = getTotalPrice('Disabled');
    const ticketPrices = {
        adult: tickets.find(ticket => ticket.type === 'Adults')?.price || 0,
        child: tickets.find(ticket => ticket.type === 'Children')?.price || 0,
        senior: tickets.find(ticket => ticket.type === 'Senior Citizen')?.price || 0,
        disabled: tickets.find(ticket => ticket.type === 'Disabled')?.price || 0
    };

    useEffect(() => {
        const bookingDetails = {
            adult: tickets.find(ticket => ticket.type === 'Adults')?.count || 0,
            child: tickets.find(ticket => ticket.type === 'Children')?.count || 0,
            senior: tickets.find(ticket => ticket.type === 'Senior Citizen')?.count || 0,
            disabled: tickets.find(ticket => ticket.type === 'Disabled')?.count || 0,
            totalTicketCount, 
            adultTotalPrice,
            childTotalPrice,
            seniorTotalPrice,
            disabledTotalPrice,
            couponCode,
            gstNo,
            ticketTotal,
            ticketPrices,
            tickets
        };
        setBookingData((prevData) => ({
            ...prevData,
            updatedBookingDetails: bookingDetails,
        }));
        setUpdatedBookingDetails(bookingDetails);
        const token = localStorage.getItem('token');

            if (totalTicketCount > 20 && !token) {
                setIsModalVisible(true);
            } else if (totalTicketCount > 20 && token && !isOtpVerified ) {
                message.error("Please add your mobile number to book more than 20 tickets")
                navigate("/accountflow")
            } else {
                setIsModalVisible(false);
            }
    }, [tickets, couponCode, gstNo, totalTicketCount]);

    const handleIncrement = (id) => {
        setTickets((prevTickets) => {
            const currentTotal = prevTickets.reduce((sum, ticket) => sum + ticket.count, 0);
            const token = localStorage.getItem('token');
    
            if (currentTotal >= 20 && !token) {
                setIsModalVisible(true);
                return prevTickets;
            } else if (totalTicketCount >= 20 && token && !isOtpVerified ) {
                message.error("Please add your mobile number to book more than 20 tickets")
                navigate("/accountflow")
                return prevTickets;
            }
    
            return prevTickets.map((ticket) =>
                ticket.id === id ? { ...ticket, count: ticket.count + 1 } : ticket
            );
        });
    };

    const handleDecrement = (id) => {
        setTickets((prevTickets) => {
            const updatedTickets = prevTickets.map((ticket) =>
                ticket.id === id && ticket.count > 0 ? { ...ticket, count: ticket.count - 1 } : ticket
            );
            return updatedTickets;
        });
    };

    const handleLogin = () => {
        setIsModalVisible(false);
        navigate('/login');
    };

    useEffect(() => {
        handleAccount();
    }, []);

    return (
        <Container className='my-5 add-ticket-container'>
            <div className='pb-3 text-mob'>
                <h4 className='fs-28 fw-700 text-black'>
                    Add your tickets
                </h4>
            </div>

            <Row 
                className='d-flex align-items-center justify-content-center' 
                style={{ borderRadius: '24px', backgroundColor: '#FFFF', border: '1px solid #A0A1AB' }}
            >
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='px-0'>
                    <div className='ticket-content' style={{borderRadius: '16px'}} >
                        <Row className="m-3 ticket-content">
                            {tickets.map((ticket) => (
                                <Col key={ticket.id} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div className="d-flex align-items-center py-3 booking-border-bottom">
                                        <div className='me-3 ticket-img-div'>
                                            <Image 
                                                src={userIcon} 
                                                alt={`${ticket.type} icon`}
                                                preview={false}
                                                className='ticket-avatar'
                                                style={{ objectFit: 'cover', borderRadius: '24px' }}
                                            />
                                        </div>
                                        <div>
                                            <h5 className='fs-16 fw-600 text-black mb-0'>
                                                {ticket.type}
                                            </h5>
                                            <p className="fs-18 fw-700 text-black mb-0">
                                                ₹{ticket.price.toFixed(2)} x {ticket.count}
                                            </p>
                                            <p className={`${window?.innerWidth > 834.98 ? 'fs-16' : window?.innerWidth <= 426 ? 'fs-12' : 'fs-14'} fw-400 text-black`}>
                                                {ticket.description}
                                            </p>
                                        </div>
                                        
                                        <div 
                                            className='d-flex align-items-center justify-content-center ticket-button ms-auto' 
                                            style={{ border: '1px solid black', borderRadius: '28px', padding: '5px 20px' }}
                                        >
                                            <span 
                                                onClick={() => handleDecrement(ticket.id)} 
                                                className="fw-700 fs-12 p-1 text-black"
                                                style={{ cursor: 'pointer'}}
                                            >
                                                <MinusOutlined />
                                            </span>
                                            
                                            <span className="fw-700 fs-18 p-1 text-black">
                                                {ticket.count}
                                            </span>
                                            
                                            <span 
                                                onClick={() => handleIncrement(ticket.id)} 
                                                className="fw-700 fs-12 p-1 text-black"
                                                style={{ cursor: 'pointer'}}
                                            >
                                                <PlusOutlined />
                                            </span>
                                        </div>
                                    </div>                               
                                </Col>
                            ))}
                        </Row>

                        <Form className='pt-2' layout="vertical">
                            <Row className='mx-4 ticket-form'>
                                {!isSubscription && (
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className='voucher-col'>
                                        <FormItem 
                                            style={{ marginBottom: '2rem' }}
                                            validateStatus={couponDetails?.count === false ? "error" : ""}
                                            help={couponDetails?.count === false ? "Invalid coupon code" : ""}
                                        >
                                            <div>
                                                <Input
                                                    className="floating-input fs-14 fw-400"
                                                    placeholder="Apply voucher code"
                                                    style={{ border: '1px solid #303136' }}
                                                    value={couponCode}
                                                    onChange={(e) => setCouponCode(e.target.value)}
                                                />
                                                <label className="floating-label fs-12">Enter Coupon Code</label>
                                            </div>
                                        </FormItem>
                                    </Col>
                                )}

                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className='voucher-col'>
                                    <FormItem 
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div>
                                            <Input
                                                className="floating-input fs-14 fw-400"
                                                placeholder="Company Name"
                                                style={{ border: '1px solid #303136' }}
                                                value={gstNo}
                                                onChange={(e) => setGstNo(e.target.value)}
                                            />
                                            <label className="floating-label fs-12">Enter GST Number</label>
                                        </div>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </Row>

            <Modal 
                visible={isModalVisible} 
                onCancel={() => setIsModalVisible(false)} 
                footer={null} 
                width={400}
                centered
            >
                <div className='py-3 text-center'>
                    <h5>Login to book more tickets</h5>
                    <p>A maximum of 20 tickets are allowed per transaction.</p>
                    <Button 
                        type='primary' 
                        size='large'
                        className='booking-btn fs-16 fw-700 mt-2'
                        style={{
                            borderRadius: '28px'
                        }}
                        onClick={handleLogin}
                    >
                        Login
                    </Button>
                </div>
            </Modal>
        </Container>
    );
};

export default AddTickets;
