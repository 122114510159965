import React from 'react'
import './RideCard.css';
import { Image } from 'antd';
import { Col, Container, Row } from 'react-bootstrap';
import WaterSlide from '../../../../assets/images/home/rides/img-water-slide.webp'
import WavePool from "../../../../assets/images/home/rides/img-wave-pool.webp"
import LazyRiver from "../../../../assets/images/home/rides/img-lazy-crazy-river.webp"
import RainDance from "../../../../assets/images/home/rides/img-rain-dance.webp"
import PlayArea from "../../../../assets/images/home/rides/img-children-play-area.webp"
import DashingCars from "../../../../assets/images/home/rides/img-dashing-car.webp"
import WavePool1 from "../../../../assets/images/home/rides/img-water-sport.webp"
import VR from "../../../../assets/images/home/rides/img-vr-theatre.webp"

const RIDES = [
    { img: WaterSlide, title: 'Water Slide', subTitle: 'Thrill and Splash: Feel the rush as you zoom down our exhilarating water slide. Perfect for adrenaline seekers, this ride promises twists, turns, and a thrilling plunge into the refreshing pool below. Get ready for an unforgettable splash!' },
    { img: WavePool, title: 'Wave Pool', subTitle: 'Ocean Waves Experience: Dive into the excitement of our wave pool, where you can ride the surf and enjoy the sensation of real ocean waves. Whether you’re riding the waves or lounging by the poolside, it’s a wave of fun for everyone.' },
    { img: LazyRiver, title: 'Lazy Crazy River', subTitle: 'Relax and Unwind: Drift along the gentle currents of our Lazy Crazy River. This winding waterway offers a serene escape with occasional surprises. Perfect for a leisurely float or a fun-filled journey with family and friends.' },
    { img: RainDance, title: 'Rain Dance', subTitle: 'Dance in the Rain: Step into our Rain Dance area and groove to the music under a refreshing shower. With lively tunes and a vibrant atmosphere, it’s the perfect spot to let loose and enjoy a rain-soaked dance party.' },
    { img: PlayArea, title: 'Swimming Pools', subTitle: 'Splash and Swim: Enjoy a relaxing swim or playful splash in our pristine swimming pools. Designed for all ages, our pools offer both shallow areas for kids and deeper sections for confident swimmers.' },   
    { img: WavePool1, title: "Children's Play Area", subTitle: 'Fun for Little Ones: Our dedicated Children’s Play Area is a heaven of fun and safety. With colorful slides, climbing frames, and water jets, it’s a perfect place for the little ones to explore, play, and make new friends.' },
    { img: VR, title: 'Virtual Reality Theatre', subTitle: 'Coming Soon' },
    { img: DashingCars, title: 'Dashing cars', subTitle: 'Coming Soon' }  
]

const RideCard = () => {
    return (
        <Container className='ride-card-container'>
            <Row className='mt-3 d-flex'>
                {
                    RIDES?.map(item => {
                        return (
                            <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12} className='text-center pb-4'>
                                <div className='position-relative'>
                                    <div className='wrapper cursor-pointer'>
                                        <Image
                                            src={item?.img}
                                            alt={item?.title}
                                            preview={false}
                                            className='object-cover w-100 image-zoom'
                                            style={{borderRadius: '16px'}}
                                        />

                                        <div className='card-head-ride position-absolute p-3'>
                                            <div className='d-flex align-items-center'>
                                                <p className={`${
                                                    window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'
                                                    } fw-700 text-black`} style={{ margin: 0 }}>{item?.title}</p>
                                            </div>

                                            <div className='additional-text'>
                                                <p className={`${
                                                    window?.innerWidth > 834.98 ? 'fs-16' : window?.innerWidth <= 425 ? 'fs-14' : 'fs-14'
                                                    } fw-400 text-black text-start mb-0`}>{item.subTitle}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )
                    })
                }

            </Row>
        </Container>
    )
}

export default RideCard
