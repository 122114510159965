import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Button, Image } from 'antd';
import { ArrowRightOutlined, DoubleRightOutlined } from '@ant-design/icons';
import useCoupons from '../../../hooks/common/useCoupon';
import { useData } from '../../../context/DataContext';
import './offers.css';

const Offers = () => {
    const navigate = useNavigate();
    const { setSharedData } = useData();
    const { coupons } = useCoupons();
    const [filteredCoupons, setFilteredCoupons] = useState([]);

    const handleNavigate = (coupon) => {
        setSharedData({ couponCode: coupon });
        navigate('/booking')
    };

    useEffect(() => {
        if (coupons) {
            const couponDetail = coupons.map((coupon) => ({
                key: coupon?._id,
                img: coupon?.mediaId?.key || "",
                couponCode: coupon?.couponCode,
                heading: coupon?.offerName || "",
                headingColor: coupon?.meta_data?.foregroundColor || "#000",
                subHeading: coupon?.description 
                    ? coupon.description.replace(/<\/?p>/g, "").replace(/<[^>]+>/g, "")
                    : "",
                offerTxt: coupon?.subTitle || "",
                offerSubTxt: coupon?.subDescription
                    ? coupon.subDescription.replace(/<\/?p>/g, "").replace(/<[^>]+>/g, "")
                    : "",
                bgLight: coupon?.meta_data?.backgroundColor || "default-bg",
                hoverGradient: coupon?.meta_data?.backgroundColor && coupon?.meta_data?.foregroundColor
                    ? `linear-gradient(180deg, ${coupon.meta_data.backgroundColor} 76.54%, ${coupon.meta_data.foregroundColor} 100%)`
                    : ""
            }));
            setFilteredCoupons(couponDetail);
        }
    }, [coupons]);
    
    return (
        <>
        <Container className='offers-containers py-5'>
             <Row className='align-items-center justify-content-center pb-5'>
                 <Col xxl={6} xl={6} lg={8} md={10} sm={12} xs={12} className='text-center'>
                     <p className='fs-28 fw-400 mb-1 text-black sub-header'>"Jump In! for the unlimited Fun at Joy n Joyy!"</p>
                     <p className={`${window?.innerWidth > 834.98 ? 'fs-36' : window?.innerWidth <= 425 ? 'fs-28' : 'fs-36'
                            } mb-1 fw-400 header text-black py-1`}>Explore Exciting Offers and Discounts!</p>
                    <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 sub-heading-text text-black mb-0`}>
                        Unlimited JOY! Look out for the latest Offers, promotions, updates and special events at Joy n Joyy!
                    </p>
                </Col>
            </Row>

            <Row className='d-flex justify-content-center align-items-stretch'>
                {filteredCoupons.slice(0, 6).map((item, index) => (
                    <Col xxl={4} xl={4} lg={6} md={10} sm={12} xs={12} key={index} className="my-3">
                        <div 
                            className={`home-offer-card ${item?.bgLight || ''} p-4`} 
                            style={{ borderRadius: '24px', cursor: 'pointer', transition: 'background 0.3s ease-in-out', background: item.bgLight }}
                            onMouseEnter={(e) => e.currentTarget.style.background = item?.hoverGradient || ''}
                            onMouseLeave={(e) => e.currentTarget.style.background = item.bgLight}
                        >
                            <div className='image-container'>
                                <Image
                                    src={item.img}
                                    alt={item.img}
                                    preview={false}
                                    className="offer-image"
                                    style={{borderRadius: '16px'}}
                                />
                            </div>
                            <div className="mt-3" style={{minHeight: '350px'}}>
                                <h4 
                                    className="fs-28 fw-700 pb-1" 
                                    style={{ color: item.headingColor }}
                                >
                                    {item?.heading}
                                </h4>
                                <p className="fs-18 fw-400 text-black">{item?.subHeading}</p>
                                <h6 className="fs-20 fw-700 text-black pt-3">{item?.offerTxt} <span style={{color: item?.headingColor}}>{item.couponCode}</span></h6>
                                <p className="fs-16 fw-400 text-black">{item?.offerSubTxt}</p>
                            </div>

                            <div className="offer-group">
                                <Button 
                                    type="default"
                                    size="large"
                                    className="fs-16 fw-700 learn-offer-btn"
                                    onClick={() => navigate(`/offerbooking/${item.key}`)}
                                >
                                    Learn More <ArrowRightOutlined />
                                </Button>
                                <Button 
                                    type="primary"
                                    size="large"
                                    className="fs-16 fw-700 home-offer-btn"
                                    onClick={() => handleNavigate(item.couponCode)}
                                >
                                    Book Now <ArrowRightOutlined />
                                </Button>
                            </div>
                        </div>
                    </Col>
                ))}

                <div className='d-flex justify-content-center my-3'>
                    <Button
                        className="fs-16 mt-3 fw-700 booking-btn"
                        type='primary'
                        size="large"
                        style={{borderColor: 'black'}}
                        onClick={() => {
                            navigate('/offerpackage');
                        }}
                    >
                        Explore More Offers <DoubleRightOutlined />
                    </Button>
                </div>
            </Row>
        </Container>
        </>
    )
}

export default Offers;