import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const DresscodeRules = () => {
    return (
        <Container fluid={false} className="pb-5">
            <Row className="d-flex align-items-center justify-content-center">
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12} >
                            <div className='py-2'>
                                <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    Dress Code & Code of Conduct Guidelines
                                </h4>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                    1. Dress Code Guidelines
                                </h6>
                                <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    To ensure a safe and enjoyable experience for all, we kindly ask guests to adhere to the following dress code:
                                </p>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li>
                                        Swimwear Requirement: Proper swimwear (nylon or lycra material) is mandatory for water rides and pools. Street clothes, denim, or any non-swimwear fabric is not permitted
                                    </li>
                                    <li>
                                        For Men: Swim trunks, board shorts, or speedos.
                                    </li>
                                    <li>
                                        For Women: Swimsuits, tankinis, or burkinis.
                                    </li>
                                    <li>
                                        Prohibited Items: Loose clothing, jewelry, and accessories that might pose safety risks on rides.
                                    </li>
                                    <li>
                                        Religious and Cultural Sensitivity: Modest swimwear options are available for purchase at our retail shops.
                                    </li>
                                </ul>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                    2. Code of Conduct
                                </h6>
                                <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    To maintain a safe, respectful, and enjoyable environment, all guests must adhere to the following conduct rules:
                                </p>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li>
                                        Safety First: Follow ride guidelines and staff instructions at all times.
                                    </li>
                                    <li>
                                        Respect for Others: Offensive language, inappropriate behavior, or harassment of any kind will not be tolerated.
                                    </li>
                                    <li>
                                        Cleanliness: Dispose of trash in designated bins and maintain the cleanliness of the park.
                                    </li>
                                    <li>
                                        Alcohol and Smoking: Only permitted in designated areas. Excessive alcohol consumption or substance abuse will lead to removal from the park.
                                    </li>
                                    <li>
                                        Lost Property: Guests are responsible for their personal belongings.
                                    </li>
                                </ul>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                    3. Additional Information
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li>
                                        Dress Code Compliance: Non-compliance with the dress code may result in restricted access to certain attractions.
                                    </li>
                                    <li>
                                        Park Decorum: Failure to adhere to the code of conduct may result in expulsion from the park without a refund.
                                    </li>
                                    <li>
                                        Accessibility: Adaptive wear for individuals with special needs is welcome.
                                    </li>
                                </ul>
                                <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    For further clarification or assistance, please contact us at 
                                    <a href="tel:+919739777566" className="text-black fw-700"> +91-9739777566 </a>
                                    or email us at 
                                    <a href="mailto:joynjoyy2024@gmail.com" className="text-black fw-700"> joynjoyy2024@gmail.com.</a>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default DresscodeRules
