import { useEffect, useState } from 'react';
import { message } from 'antd';
import { makeRequest } from '../../services/api';

const useCoupons = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [coupons, setCoupons] = useState()

    const getCoupons = async ()=> {
        try {
            const response = await makeRequest('get', '/coupons/all');
            if(response) {
                setCoupons(response.data.coupons)
            } else {
                message.error("error fetching coupons")
            }
        }   catch (err) {
                throw new Error('Cannot fetch the couponse', err)
        }   finally {
                setIsLoading(false)
        }
    }

    const getCouponById = async (id) => {
        try {
            const response = await makeRequest('get', `/coupons/${id}`);
            if (response?.data) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };    

    useEffect(() => {
        getCoupons()
    } ,[]);

    return {
        coupons,
        getCouponById,
        isLoading
    }
}

export default useCoupons;