import React from 'react';
import { Col, Row } from "react-bootstrap";
import { Modal, Image } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import shuttle from "../../assets/images/planyourvisit/icons/shuttle.svg"
import bus from "../../assets/images/planyourvisit/icons/bus.svg"
import train from "../../assets/images/planyourvisit/icons/train.svg"
import car from "../../assets/images/planyourvisit/icons/car.svg"
import pickup from "../../assets/images/planyourvisit/icons/pickup.svg"
import shuttleImg from "../../assets/images/planyourvisit/img-shuttlebus.webp"
import byBusImg from "../../assets/images/planyourvisit/img-bus.webp"
import byTrainImg from "../../assets/images/planyourvisit/img-train.webp"
import byPrivateCarImg from "../../assets/images/planyourvisit/img-car.webp"
import pickupImg from "../../assets/images/planyourvisit/img-pickup.webp"
import "./PlanVisitModal.css"

const PlanVisitModal = ({ visible, onClose, content }) => {

    let icon;
    let img;

    switch (content.title) {
        case "By Shuttle Bus / Tempo":
            icon = shuttle
            img = shuttleImg
            break;
        
        case "By Bus":
            icon = bus
            img = byBusImg
            break;
        
        case "By Train":
            icon = train
            img = byTrainImg
            break;
        
        case "By Private Car":
            icon = car
            img = byPrivateCarImg
            break;

        case "Pick and drop facility ":
            icon = pickup
            img = pickupImg
            break;
    }

    const distances = {
        byBus: [
          { city: "Mangaluru", distance_time: "180 km (Approx. 4 hours)" },
          { city: "Goa", distance_time: "150 km (Approx. 3 hours)" },
          { city: "Hubli", distance_time: "184 km (Approx. 3.5 hours)" },
          { city: "Shivamogga", distance_time: "130 km (Approx. 3 hours)" },
          { city: "Haveri", distance_time: "176 km (Approx 3.5 hours)" },
          { city: "Bangalore", distance_time: "450 km (Approx. 8 hours)" }
        ],
        byTrain: [
          { city: "Mangaluru", distance_time: "200 km (Approx. 5 hours by train)" },
          { city: "Bengaluru", distance_time: "520 km (Approx. 9 hours by train)" },
          { city: "Goa", distance_time: "120 km (Approx. 3 hours by train)" },
          { city: "Mysuru", distance_time: "456 km (Approx. 13 hours by train)" }
        ],
        byCar: [
          { city: "Mangaluru", distance_time: "180 km (Approx. 4 hours)" },
          { city: "Goa", distance_time: "150 km (Approx. 3 hours)" },
          { city: "Hubballi", distance_time: "184 km (Approx. 3.5 hours)" },
          { city: "Shivamogga", distance_time: "130 km (Approx. 3 hours)" },
          { city: "Haveri", distance_time: "176 km (Approx 3.5 hours)" },
          { city: "Bengaluru", distance_time: "450 km (Approx. 8 hours)" }
        ],
        pickup: [
            {planning: "Planning a group visit? We've got you covered! For groups arriving from distant locations, Joy n Joyy offers a hassle-free pickup and drop facility from Honnavar. Simply call our team or email us your booking details, and we’ll arrange transportation to and from the nearest bus station in Honnavar* Travel with ease and focus on the fun that awaits you!"},
            {mail: "Email :", mail_to: " joynjoyy2024@gmail.com"},
            {contact: "Contact :", contact_to: "+91-9739777566"},
            {terms: "*terms & conditions apply"}
            
        ]
    };

    return (
        <Modal
            visible={visible}
            footer={null}
            centered
            closable={false}
            width={928}
            height={612}
            onCancel={onClose}
        >
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                    <div className='right-img' style={{ position: "relative" }}>
                        <Image 
                            src={img}
                            alt="Image"
                            style={{borderRadius: "24px" }}
                            preview={false}
                        />
                    </div> 
                </Col>
                {content.title === "By Shuttle Bus / Tempo" && (
                    <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                    <div>
                        <Image 
                            src={icon}
                            alt={"logo"}
                            preview={false}
                            style={{ width: '64px', height: '64px' }}
                            className='my-3'
                        />
                        <CloseOutlined
                            className='close-button'
                            style={{
                                position: "absolute",
                                top: "20px",
                                right: "20px",
                                fontSize: "25px",
                                cursor: "pointer",
                            }}
                            onClick={onClose}
                        />
                    </div>
                    <div>
                        <p  
                            className={`${
                                window?.innerWidth > 835
                                ? "fs-24"
                                : window?.innerWidth <= 680
                                ? "fs-18"
                                : "fs-24" 
                            } mt-1 fw-700 text-black`}
                        >
                            Shuttle Bus / Tempo
                        </p>
                        <p  
                            className="fs-16 mt-1 fw-400 text-black"
                        >
                            We’ve made it easy for visitors from across the region to reach Joy n Joyy with convenient public transport options:
                        </p>
                        <p  
                            className={`${
                                window?.innerWidth > 835
                                ? "fs-16"
                                : window?.innerWidth <= 680
                                ? "fs-14"
                                : "fs-16" 
                            } mt-1 fw-700 text-black`}
                        >
                            KSRTC Shuttle Buses:
                        </p>
                        <p  
                            className="fs-14 mt-1 fw-400 text-black"
                        >
                            Thee shuttle buses from various destination within Honnavar taluk and from the neighbourhood taluks outside of Honnavar runs at regular intervals, so you can easily reach Honnavar and from there catch a local auto to reach the park.
                        </p>
                        <p  
                            className={`${
                                window?.innerWidth > 835
                                ? "fs-16"
                                : window?.innerWidth <= 680
                                ? "fs-14"
                                : "fs-16" 
                            } mt-1 fw-700 text-black`}
                        >
                            Private Tempo Service:
                        </p>
                        <p  
                            className="fs-14 mt-1 fw-400 text-black"
                        >
                            Visitors from Honnavar and neighbourhood taluks can take advantage of private tempo services available at regular intervals.
                            <br></br>
                            <br></br>
                            These tempos provide a direct and comfortable ride to Honnavar. From Honnavar you can catch a local auto to reach the park.
                        </p>
                    </div>                    
                </Col>
                )}
                {content.title === "By Bus" && (
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div>
                        <Image 
                            src={icon}
                            alt={"logo"}
                            preview={false}
                            style={{ width: '64px', height: '64px' }}
                            className='my-3'
                        />
                        <CloseOutlined
                            className='close-button'
                            style={{
                                position: "absolute",
                                top: "20px",
                                right: "20px",
                                fontSize: "25px",
                                cursor: "pointer",
                            }}
                            onClick={onClose}
                        />
                    </div>
                    <div>
                        <p
                            className={`${
                                window?.innerWidth > 835
                                ? "fs-24"
                                : window?.innerWidth <= 680
                                ? "fs-18"
                                : "fs-24" 
                            } mt-1 fw-700 text-black`}
                        >
                            By Bus (from Different Cities)
                        </p>
                        {distances.byBus.map(({ city, distance_time}, index) => (
                            <div key={index}>
                                <p 
                                    className={`${
                                        window?.innerWidth > 835
                                        ? "fs-16"
                                        : window?.innerWidth <= 680
                                        ? "fs-14"
                                        : "fs-16" 
                                        } mb-0`}
                                >
                                    <span className='fw-800'>{city}:</span> {distance_time}
                                </p>
                          </div>
                        ))}
                        <p  
                            className="fs-14 mt-3 fw-400 text-black"
                        >
                            Note: All buses arrive at the Honnavar KSRTC Bus Station, from where the  local autorickshaws or private pick-up facilities are available 
                        </p>
                    </div>
                </Col>
                )}
                {content.title === "By Train" && (
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div>
                            <Image 
                                src={icon}
                                alt={"logo"}
                                preview={false}
                                style={{ width: '64px', height: '64px' }}
                                className='my-3'
                            />
                            <CloseOutlined
                                className='close-button'
                                style={{
                                    position: "absolute",
                                    top: "20px",
                                    right: "20px",
                                    fontSize: "25px",
                                    cursor: "pointer",
                                }}
                                onClick={onClose}
                            />
                        </div>
                        <div>
                            <p  
                                className={`${
                                    window?.innerWidth > 835
                                    ? "fs-24"
                                    : window?.innerWidth <= 680
                                    ? "fs-18"
                                    : "fs-24" 
                                } mt-1 fw-700 text-black`}
                            >
                                By Train:
                            </p>
                            {distances.byTrain.map(({ city, distance_time}, index) => (
                                <div key={index}>
                                    <p 
                                        className={`${
                                            window?.innerWidth > 835
                                            ? "fs-16"
                                            : window?.innerWidth <= 680
                                            ? "fs-14"
                                            : "fs-16" 
                                            } mb-0`}
                                    >
                                        <span className='fw-800'>{city}:</span> {distance_time}
                                    </p>
                            </div>
                            ))}
                            <p  
                                className="fs-14 mt-3 fw-400 text-black"
                            >
                                After reaching Honnavar Railway Station, you can easily take our shuttle service to the park or opt for a taxi/auto service. 
                            </p>
                        </div>
                    </Col>
                )}
                {content.title === "By Private Car" && (
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div>
                            <Image 
                                src={icon}
                                alt={"logo"}
                                preview={false}
                                style={{ width: '64px', height: '64px' }}
                                className='my-3'
                            />
                            <CloseOutlined
                                className='close-button'
                                style={{
                                    position: "absolute",
                                    top: "20px",
                                    right: "20px",
                                    fontSize: "25px",
                                    cursor: "pointer",
                                }}
                                onClick={onClose}
                            />
                        </div>
                        <div>
                            <p  
                                className={`${
                                    window?.innerWidth > 835
                                    ? "fs-24"
                                    : window?.innerWidth <= 680
                                    ? "fs-18"
                                    : "fs-24" 
                                } mt-1 fw-700 text-black`}
                            >
                                By Private Car:
                            </p>
                            {distances.byCar.map(({ city, distance_time}, index) => (
                                <div key={index}>
                                    <p  className={`${
                                        window?.innerWidth > 835
                                        ? "fs-16"
                                        : window?.innerWidth <= 680
                                        ? "fs-14"
                                        : "fs-16" 
                                        } mb-0`}
                                    >
                                        <span className='fw-800'>{city}:</span> {distance_time}
                                    </p>
                            </div>
                            ))}
                            <p  
                                className={`${
                                    window?.innerWidth > 835
                                    ? "fs-16"
                                    : window?.innerWidth <= 680
                                    ? "fs-14"
                                    : "fs-16" 
                                    } mb-0 mt-3 fw-400 text-black`}
                            >
                                Ample parking space is available at the park for private cars.
                            </p>
                        </div>
                    </Col>
                )}
                {content.title === "Pick and drop facility " && (
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className=''>
                            <Image 
                                src={icon}
                                alt={"logo"}
                                preview={false}
                                style={{ width: '64px', height: '64px' }}
                                className='my-3'
                            />
                            <CloseOutlined
                                className='close-button'
                                style={{
                                    position: "absolute",
                                    top: "20px",
                                    right: "20px",
                                    fontSize: "25px",
                                    cursor: "pointer",
                                }}
                                onClick={onClose}
                            />
                        </div>
                        <div>
                            <p  
                                className={`${
                                    window?.innerWidth > 835
                                    ? "fs-24"
                                    : window?.innerWidth <= 425
                                    ? "fs-18"
                                    : "fs-24"
                                } mt-1 fw-700 text-black`}
                            >
                                Convenient Pickup and Drop for Groups
                            </p>
                            {distances.pickup.map(({ planning, mail, mail_to, contact, contact_to, terms}, index) => (
                                <div key={index}>
                                    <p 
                                        className={`${
                                            window?.innerWidth > 835
                                            ? "fs-16"
                                            : window?.innerWidth <= 680
                                            ? "fs-14"
                                            : "fs-16" 
                                            } fw-400 text-black`}
                                    >
                                        <span>{planning}</span>

                                        <span className='fw-800'>{mail}</span>
                                        <a href={`mailto:${mail_to}`} className="mailto-link">{mail_to}</a>

                                        <span className='fw-800'>{contact}</span>
                                        <a href={`tel:${contact_to}`} className="phone-link">{contact_to}</a>
                                        
                                        <span>{terms}</span>
                                    </p>
                            </div>
                            ))}
                        </div>
                    </Col>
                )}
            </Row>
        </Modal>
    );
};

export default PlanVisitModal;
