import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './bookingslider.css';
import BookingImage1 from '../../../assets/images/offer-booking/slide/img-offers-detail-page-carousal-1.webp';
import BookingImage2 from '../../../assets/images/offer-booking/slide/img-offers-detail-page-carousal-2.webp';
import BookingImage3 from '../../../assets/images/offer-booking/slide/img-offers-detail-page-carousal-3.webp';
import BookingImage4 from '../../../assets/images/offer-booking/slide/img-offers-detail-page-carousal-4.webp';
import { Navigation } from 'swiper/modules';
import { Image } from 'antd';
import { Container } from 'react-bootstrap';

const BOOKING_IMAGES = [
    BookingImage1,
    BookingImage2, 
    BookingImage3, 
    BookingImage4
];

const BookingSlider = () => {
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 425);

    React.useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 425);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Container fluid={true} className='img-container py-4'>
            <div className=''>
                {!isMobile ? (
                    <Swiper
                        slidesPerView={'auto'}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                        modules={[Navigation]}
                        className="bookingslider"
                    >
                        {BOOKING_IMAGES.map((imgSrc, index) => (
                            <SwiperSlide key={index} className="booking-slide">
                                <div className='w-100 h-100 p-2'>
                                    <div className='custom-slide-images'>
                                        <Image
                                            src={imgSrc}
                                            alt={`Booking Image ${index + 1}`}
                                            preview={false}
                                            style={{ borderRadius: '16px' }}
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                        <div className="swiper-button-prev" />
                        <div className="swiper-button-next" />
                    </Swiper>
                ) : (
                    BOOKING_IMAGES.map((imgSrc, index) => (
                        <div key={index} className='p-1'>
                            <div>
                                <Image
                                    src={imgSrc}
                                    alt={`Booking Image ${index + 1}`}
                                    preview={false}
                                    style={{ borderRadius: '16px' }}
                                />
                            </div>
                        </div>
                    ))
                )}
            </div>
        </Container>
    );
};

export default BookingSlider;
