import { Spin } from 'antd'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import './Suspense.css'

const SuspenseLoader = () => {
    return (
        <Container fluid={true} className='content'>
            <Row className='h-100'>
                <Col className='h-100'>
                    <div className='d-flex align-items-center justify-content-center h-100'>
                        <Spin size="large" />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default SuspenseLoader;
