import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Button, Image } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import './ShopCollection.css';
import { useNavigate } from 'react-router-dom';
import useRetail from '../../../hooks/useRetail';

const ShopCollection = () => {
    const navigate = useNavigate();
    const { getMerchandiseData } = useRetail();
    const [merchandise, setMerchandise] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getMerchandiseData();
            if (data) {
                setMerchandise(data);
            }
        };
        fetchData();
    }, [getMerchandiseData]);

    return (
        <Container fluid={false} className="py-5">
            <Row className="py-3">
                {merchandise.map((item, index) => (
                    <Col 
                        key={index}
                        // xxl={index === 0 ? 6 : 3} 
                        // xl={index === 0 ? 6 : 3} 
                        xxl={3}
                        xl={3}
                        lg={6} 
                        md={6} 
                        sm={12}
                        xs={12} 
                        className="pb-4"
                    >
                        <div className="position-relative shop-wrapper">
                            <Image
                                src={item?.mediaId?.key}
                                alt={item.name}
                                preview={false}
                                className='shop-zoom'
                            />
                            <div className="card-footer">
                                <div className="shopping-items">
                                    <h4 className="fs-16 fw-700 my-1 text-black" >{item.name}</h4>
                                </div>
                                <div className='card-content'>
                                    <p className="fs-14 fw-400 subtitle mt-0">{item.desc.replace(/<[^>]+>/g, '')}</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                ))}

                <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={12} className="pb-4">
                    <div className="position-relative">
                        <div className="position-relative shop-member">
                            <p className={`${window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'} fw-700 mx-4 pt-3`}>
                                Explore Exclusive Packages
                            </p>
                            <p className="mx-4 fs-14 fw-400">
                                Explore our exclusive packages for corporate groups, schools, colleges, and associates. 
                                Whether you're planning a day of fun or looking for long-term benefits, our tailored packages offer something special for everyone. 
                                Click below to discover how you can make your JoynJoyy experience even more memorable!
                            </p>
                            <Button
                                type="default"
                                className="fs-16 fw-700 custom-btn"
                                size="large"
                                style={{background: '#ffff'}}
                                onClick={() => {
                                    navigate('/booking');
                                }}
                            >
                                View more
                                <ArrowRightOutlined />
                            </Button>
                        </div>
                    </div>
                </Col>

            </Row>
        </Container>
    );
};

export default ShopCollection;
