import React, { useState, useEffect } from 'react';
import { Col, Row } from "react-bootstrap";
import { Button, Image } from "antd";
import { CheckCircleFilled } from '@ant-design/icons';
import './StayOptions.css';
import Tented1 from "../../../assets/images/custom-booking/stay/img-basic-tent.webp";
import Tented2 from "../../../assets/images/custom-booking/stay/img-luxury-tent.webp";
import Container1 from "../../../assets/images/custom-booking/stay/img-basic-container.webp";
import Container2 from "../../../assets/images/custom-booking/stay/img-deluxe-container.webp";
import Home1 from "../../../assets/images/custom-booking/stay/img-budget-homestay.webp";
import Home2 from "../../../assets/images/custom-booking/stay/img-premium-homestay.webp";
import Resorts1 from "../../../assets/images/custom-booking/stay/img-3star-resort.webp";
import Resorts2 from "../../../assets/images/custom-booking/stay/img-5star-resort.webp";

const options = [
    {
        id: "Tented",
        title: "Tented Accommodation",
        options: [
            { id: 1, name: "Basic Tent Package", description: "₹1000 per night (shared washrooms, simple setup)", image: Tented1 },
            { id: 2, name: "Luxury Tents", description: "₹2500 per night (private washrooms, luxury bedding)", image: Tented2 }
        ]
    },
    {
        id: "Container",
        title: "Container Stays",
        options: [
            { id: 3, name: "Basic Container Stay", description: "₹1800 per night (compact, minimalistic setup)", image: Container1 },
            { id: 4, name: "Deluxe Container Stay", description: "₹3000 per night (more space, private bathroom, and air conditioning)", image: Container2 }
        ]
    },
    {
        id: "Home",
        title: "Home Stays",
        options: [
            { id: 5, name: "Budget Home Stay", description: "₹2000 per night (local hosts, shared amenities)", image: Home1 },
            { id: 6, name: "Premium Home Stay", description: "₹4000 per night (private rooms, exclusive services)", image: Home2 }
        ]
    },
    {
        id: "Resorts",
        title: "Resorts",
        options: [
            { id: 7, name: "3-Star Resort", description: "₹3500 per night (swimming pool, breakfast included)", image: Resorts1 },
            { id: 8, name: "5-Star Resort", description: "₹7000 per night (luxury services, meals included)", image: Resorts2 }
        ]
    }
];

const StayOptions = ({ setCustomDetails, setActiveKey, customDetails }) => {
    const [stayOptions, setStayOptions] = useState(customDetails.stayOption || []);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 530);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 530);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleClick = (id, name) => {
        setStayOptions((prev) =>
            prev.some((item) => item.id === id)
                ? prev.filter((item) => item.id !== id)
                : [...prev, { id, name }]
        )       
    };   

    useEffect(() => {
        setCustomDetails((prevDetails) => ({
            ...prevDetails,
            stayOption: stayOptions,
        }));
    }, [stayOptions, setCustomDetails]);

    const handleBack = () => {
        setActiveKey('2');
    }

    const handleContinue = () => {
        setActiveKey('4')
    }

    return (
        <div className='stay-options'>
            <h4 className='fs-24 fw-700 py-2'>Select Your Stay Option</h4>

            <Row>
                {options.map((category) => (
                    <Col key={category.id} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="pb-4">
                        <h6 className="fs-18 fw-700">{category.title}</h6>

                        <Row>
                            {category.options.map((option) => (
                                <Col key={option.id} xxl={6} xl={6} lg={12} md={12} sm={12} xs={12} className="pb-3">
                                    <div
                                        className='p-3 d-flex gap-3 justify-content-between'
                                        style={{
                                            background: "#F8F8F9",
                                            borderRadius: '24px',
                                            border: stayOptions.some((item) => item.id === option.id)
                                                ? "2px solid #000000"
                                                : "1px solid transparent",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => handleClick(option.id, option.name)}
                                    >
                                        <div className="d-flex flex-column">
                                            <CheckCircleFilled
                                                className="check-circle"
                                                style={{
                                                    color: stayOptions.some((item) => item.id === option.id)
                                                        ? "#2263E3"
                                                        : "transparent",
                                                    border: stayOptions.some((item) => item.id === option.id)
                                                        ? "1px solid transparent"
                                                        : "1px solid #303136",
                                                }}
                                            />
                                            <p className="fs-14 fw-700 destination-name my-2">{option.name}</p>
                                            <p className='fs-14 fw-400'>{option.description}</p>
                                        </div>

                                        <div className="stay-image-container">
                                            <Image
                                                src={option.image}
                                                alt={option.name}
                                                preview={false}
                                                className="select-stay-img"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                ))}
            </Row>

            <Row className='d-flex pt-4'>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='text-end'>
                    <Button 
                        type="default" 
                        className="fs-14 fw-600 custom-back-btn m-1" 
                        size="large" 
                        style={{ borderColor: '#272727' }}
                        onClick={handleBack}
                    >
                        Back
                    </Button>
                
                    <Button 
                        type="primary" 
                        className="fs-14 fw-600 custom-book-btn m-1" 
                        size="large"
                        onClick={handleContinue}
                    >
                        Continue
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default StayOptions;
