import { useState } from "react";
import { message } from "antd";
import { makeRequest } from "../../services/api";
import { useNavigate } from "react-router-dom";

const useCustomExperience = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const createCustomBooking = async (customDetails) => {
        const transformedPayload = {
            email: customDetails.email || "",
            phone: customDetails.phone || "",
            name: customDetails.name || "",
            tripDays: customDetails.tripDays.toString(),
            date: customDetails.date,
            startDate: customDetails.startDate,
            endDate: customDetails.endDate,
            noOfPeople: customDetails.noOfPeople.toString(),
            destination: customDetails.destination?.map(destination => destination.name) || [],
            stayOption: customDetails.stayOption?.map(option => option.name) || [],
            food: customDetails.food?.map(food => food.foodName) || [],
            transportation: customDetails.transportation?.map(trans => ({
                name: trans.name,
                quantity: trans.quantity.toString(),
            })) || [],
            itineraryPlan: customDetails.itineraryPlan?.map(itinerary => ({
                date: itinerary.date,
                name: itinerary.name,
            })) || [],
            additionalServices: customDetails.additionalServices?.map(service => service.name) || [],
            optional: customDetails.optional?.map(option => ({
                name: option.name,
                quantity: option.quantity.toString(),
            })) || [],            
            specialRequests: customDetails.specialRequests || "", 
        };

        setIsLoading(true);
        try {
            const response = await makeRequest("post", "/customer/custom-booking", transformedPayload);
            message.success("Booking created! Admin Team will reach out to you");
            navigate("/");
            return response;
        } catch (error) {
            console.error("API Error:", error);
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    return { createCustomBooking, isLoading };
};

export default useCustomExperience;
