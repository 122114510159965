import { useEffect, useState } from "react";

const useResize = () => {
  const [isMobile, setIsMobile] = useState(false);

  const updateWindowDimensions = () => {
      if (window?.innerWidth > 834) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }
  };

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  return {
    isMobile
  };
};

export default useResize;
