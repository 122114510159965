import { useEffect, useState } from 'react';
import { message } from 'antd';
import { makeRequest } from '../services/api';

const useRetail = () => {
    const [isBooking, setIsBooking] = useState(false);
    const [bookingData, setBookingData] = useState(null);
    const [priceMeta, setPriceMeta] = useState(null);
    const [socialMeta, setSocialMeta] = useState(null);
    const [diningData, setDiningData] = useState(null);
    const [categoryData, setCategoryData] = useState(null);
    const [merchandiseData, setMerchandiseData] = useState(null);

    const createBooking = async (bookingDetails) => {
        setIsBooking(true);
        try {
            const response = await makeRequest('post', '/ticket-booking/create', bookingDetails);
    
            if (response?.data && response.statusCode === 200) {
                setBookingData(response.data);
                return response.data;
            } else {
                throw new Error(response.message || 'Booking creation failed');
            }
        } catch (error) {
            message.error(error.message || 'Booking creation failed');
            return null;
        } finally {
            setIsBooking(false);
        }
    };

    const createPaymentLink = async (orderId) => {
        try {
            const response = await makeRequest('post', '/payments/create-link', { orderId, channel: 'web' });

            if (response?.data) {
                return response.data.paymentLink;
            } else {
                throw new Error('Failed to create payment link');
            }
        } catch (error) {
            message.error(error.message || 'Failed to create payment link');
            return null;
        }
    };

    const getBookingDetails = async (id) => {
        try {
            const response = await makeRequest('get', `/ticket-booking/${id}`);
            if (response?.data) {
                setBookingData(response.data);
                return response.data;
            } else {
                throw new Error('Failed to retrieve booking details');
            }
        } catch (error) {
            message.error(error.message || 'Failed to retrieve booking details');
            return null;
        }
    };

    const getSettings = async () => {
        try {
            const response = await makeRequest('get', '/settings');
            if (response?.data && response.statusCode === 200) {
                setPriceMeta(response.data.priceMeta);
                setSocialMeta(response.data.socialMeta);
            } else {
                throw new Error('Failed to fetch settings');
            }
        } catch (error) {
            message.error(error.message || 'Failed to fetch settings');
        }
    };

    const getDiningData = async () => {
        if (diningData) return; 
        
        try {
            const response = await makeRequest('get', `/dinning/all`);
            if (response?.data) {
                setDiningData(response.data);
                return response.data;
            } else {
                throw new Error('Failed to fetch dining data');
            }
        } catch (error) {
            message.error(error.message || 'Failed to fetch dining data');
        }
    };

    const getCategoryData = async () => {
        if (categoryData) return categoryData;

        try {
            const response = await makeRequest("get", `/dinning/category`);
            if (response?.data) {
                setCategoryData(response.data);
                return response.data;
            } 
        } catch (error) {
            message.error(error.message || "Failed to fetch category data");
        }
    };

    const getMerchandiseData = async () => {
        if (merchandiseData) return; 
        
        try {
            const response = await makeRequest('get', `/merchandise/all`);
            if (response?.data?.merchandise) {
                setMerchandiseData(response.data.merchandise);
                return response.data.merchandise;
            } else {
                throw new Error('Failed to fetch merchandise data');
            }
        } catch (error) {
            message.error(error.message || 'Failed to fetch merchandise data');
            // return null;
        }
    };

    useEffect(() => {
        getSettings();
        getDiningData();
        getCategoryData();
        getMerchandiseData();
    }, []);

    
    return {
        isBooking,
        bookingData,
        priceMeta,
        socialMeta,
        diningData,
        categoryData,
        merchandiseData,
        createBooking,
        createPaymentLink,
        getBookingDetails,
        getDiningData,
        getCategoryData,
        getMerchandiseData
    };
};

export default useRetail;
