import { Badge, Button, Image } from 'antd'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './NonVegetarianFood.css';
import { ArrowRightOutlined } from '@ant-design/icons'
import useRetail from '../../../hooks/useRetail'
import { useNavigate } from 'react-router-dom'

const NonVegetarianFood = () => {
    const { getDiningData, getCategoryData } = useRetail();
    const [ nonVegItems, setNonVegItems ] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const diningData = await getDiningData();
            const categoryData = await getCategoryData();
    
            if (diningData && categoryData) {
                const nonVegCategory = categoryData.find(category => category.name.trim().toLowerCase() === 'non-veg');
    
                if (nonVegCategory) {
                    const filteredItems = diningData.foods.filter(item => item.categoryId === nonVegCategory._id);
                    setNonVegItems(filteredItems);
                }
            }
        };
        fetchData();
    }, []);

    return (
        <Container className='py-5'>
            <Row className="d-flex align-items-center justify-content-center">
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='text-center'>
                
                <div className="non-vegbanner"></div>

                <Row className="d-flex align-items-center justify-content-between py-5">
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12} className="food-content text-start">
                        <Badge 
                            color="#AE2100" 
                            text="NON-VEGETARIAN FEASTS"
                            className={`${ window?.innerWidth > 834.98 ? "fs-20" : window?.innerWidth <= 425 ? "fs-14" : "fs-20" } fw-600 badge-custom2`}
                        />
                        <h2 className={`${ window?.innerWidth > 834.98 ? "fs-28" : window?.innerWidth <= 425 ? "fs-20" : "fs-24" } fw-700 pt-2`}>
                            Satisfy Your Cravings
                        </h2>
                        <p className={`${ window?.innerWidth > 834.98 ? "fs-20" : window?.innerWidth <= 425 ? "fs-16" : "fs-18" } fw-400`}>
                            Indulge in a range of non-vegetarian specialties cooked to perfection.
                            From succulent meats to flavorful gravies, this section promises a feast for meat lovers.
                        </p>
                    </Col>
                
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} className="direction-btn text-end">
                        <div>
                            <Button 
                                className={`${ window?.innerWidth > 834.98 ? "fs-20" : window?.innerWidth <= 425 ? "fs-16" : "fs-16" } fw-700 booking-btn direct-btn`}
                                size="large"
                                onClick={() => {
                                    navigate('/booking');
                                }}
                            >
                                <span className='fs-5'><ArrowRightOutlined/></span>
                                Get Foods
                            </Button>
                            <p className={`${ window?.innerWidth > 834.98 ? "fs-20" : window?.innerWidth <= 425 ? "fs-16" : "fs-18" } fw-400 food-content pt-3`}>Dining only available at the park</p>
                        </div>
                    </Col>
                </Row>

                <Row>
                    {nonVegItems.map((item, index) => (
                        <Col key={index} xxl={4} xl={4} lg={4} md={6} sm={12} xs={12} className="mb-4">
                            <div className="text-center">
                                <Image
                                    src={item.mediaId?.key}
                                    alt={item.name}
                                    preview={false}
                                    style={{ borderRadius: '24px' }}
                                />
                                <div className="d-flex justify-content-between align-items-center pt-3">
                                    <h4 
                                        className={`${ window?.innerWidth > 834.98 ? "fs-24" : window?.innerWidth <= 425 ? "fs-18" : "fs-22" } fw-700 text-start`}
                                    >
                                        {item.name}
                                    </h4>
                                    <div className="d-flex align-items-center">
                                        <span className="ms-2 fs-20 text-success">★</span>
                                        <span className="fw-400 fs-20 ms-1 text-success">4.5</span>                                           
                                        {/* <p className="fw-400 fs-20 mb-0 ms-2">({item.reviews})</p> */}
                                    </div>
                                </div>

                                <p 
                                    className={`${ window?.innerWidth > 834.98 ? "fs-20" : window?.innerWidth <= 425 ? "fs-18" : "fs-20" } fw-400 text-start`}
                                >
                                    ₹{item.price.toFixed(2)}
                                </p>
                            </div>
                        </Col>
                    ))}
                </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default NonVegetarianFood
