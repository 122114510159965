import React, { useEffect, useState } from "react";
import FAQ from "../../components/FAQPageSection/FAQ/FAQ";
import { Container } from "react-bootstrap";
import NewsAndUpdates from "../../components/homepage/NewsAndUpdates/NewsAndUpdates";
import FaqBanner from "../../components/FAQPageSection/FaqBanner/FaqBanner";
import Offers from "../../components/homepage/Offers/Offers";

const FAQPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  const [selectedCategory, setSelectedCategory] = useState("Ticket related queries");

  return (
    <Container fluid={true} className="p-0">
      <FaqBanner 
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <FAQ selectedCategory={selectedCategory}/>
      <Offers />
      <NewsAndUpdates />
    </Container>
  );
};

export default FAQPage;
