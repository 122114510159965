import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Activities from './pages/Activities/Activities';
import OfferPackage from './pages/OfferPackage/OfferPackage';
import TopDestVisiter from './pages/TopDestVisiter/TopDestVisiter';
import OfferBooking from './pages/OfferBooking/OfferBooking';
import TopDestBooking from "./pages/TopDestBooking/TopDestBooking";
import DiningPage from "./pages/DiningPage/DiningPage";
import ShoppingPage from "./pages/ShoppingPage/ShoppingPage";
import FAQPage from "./pages/FAQPage/FAQPage";
import TestimonialPage from "./pages/TestimonialPage/TestimonialPage";
import CareersPage from "./pages/CareersPage/CareersPage";
import CareersDetails from "./pages/CareersDetails/CareersDetails";
import GuestServicePage from "./pages/GuestServicePage/GuestServicePage";
import PlanYourVisit from "./pages/PlanYourVisit/PlanYourVisit";
import RulesAndReg from "./pages/RulesAndReg/RulesAndReg";
import ComingSoonPage from "./pages/ComingSoonPage/ComingSoonPage";
import Detail from "./components/CareersDetailsPage/Mobile/Detail/Detail";
import IndependencePage from "./pages/IndependencePage/IndependencePage";
import BookingPage from "./pages/RetailBooking/BookingPage";
import ReviewBookingPage from "./pages/RetailBooking/ReviewBookingPage";
import SignupPage from "./pages/GroupBookingPage/SignupPage";
import LoginPage from "./pages/GroupBookingPage/LoginPage";
import AccountSuccessPage from "./pages/GroupBookingPage/AccountSuccessPage";
import SentRequest from "./pages/GroupBookingPage/SentRequestPage";
import GroupBookingPage from "./pages/GroupBookingPage/GroupBookingPage";
import AccountFlow from "./pages/AccountFlow/AccountFlow";
import PaymentPage from "./pages/PaymentPage/PaymentPage";
import PaymentSuccess from "./pages/PaymentSuccess/PaymentSuccess";
import AboutusPage from "./pages/AboutusPage/AboutusPage";
import DressCodePage from "./pages/DressCodePage/DressCodePage";
import SafetyHygienePage from "./pages/SafetyHygienePage/SafetyHygienePage";
import ParkTimingsPage from "./pages/ParkTimingsPage/ParkTimingsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage";
import TermsConditionPage from "./pages/TermsConditionPage/TermsConditionPage";
import CancellationPage from "./pages/CancellationPage/CancellationPage";
import HeightRequirementPage from "./pages/HeightRequirementPage/HeightRequirementPage";
import ContactUsPage from "./pages/ContactUsPage/ContactUsPage";
import RefundReturn from "./pages/RefundReturn/RefundReturn";
import ServicesPage from "./pages/ServicesPage/ServicesPage";
import EnvironmentPage from "./pages/EnvironmentPage/EnvironmentPage";
import ResetPassword from "./components/GroupBooking/ResetPassword/ResetPassword";
import ForgotPassword from "./components/GroupBooking/ForgotPassword/ForgotPassword";
import { getToken } from "./services/api";
import GreenDesignPage from "./pages/GreenDesignPage/GreenDesignPage";
import CustomBookingPage from "./pages/CustomBookingPage/CustomBookingPage";
import PackagesPage from "./pages/PackagesPage/PackagesPage";
import OtpVerifyPage from "./pages/GroupBookingPage/OtpVerifyPage";
import ForgotPasswordPage from "./pages/GroupBookingPage/ForgotPasswordPage";
import ResetPwdPage from "./pages/GroupBookingPage/ResetPwdPage";
import { BookingDataProvider } from "./context/BookingDataContext";
const Home = React.lazy(() => import("./pages/Home/Home"));
const Layout = React.lazy(() => import("./common/Layout/Layout"));

const routesList = (isLoggedIn, isMobile, currentImages) => [
    {
        path: "",
        element: <Layout><Home currentImages={currentImages}/></Layout>
    },

    {
        path: "home",
        element: <Layout><Home currentImages={currentImages}/></Layout>
    },

    {
        path: "activities",
        element: <Layout><Activities /></Layout>
    },

    {
        path: "offerpackage",
        element: <Layout><OfferPackage /></Layout>
    },

    {
        path: "destination",
        element: <Layout><TopDestVisiter /></Layout>
    },

    {
        path: "offerbooking/:id",
        element: <Layout><OfferBooking /></Layout>
    },

    {
        path: "shopping",
        element: <Layout><ShoppingPage /></Layout>
    },

    {
        path: "faq",
        element: <Layout><FAQPage /></Layout>
    },

    {
        path: "dining",
        element: <Layout><DiningPage /></Layout>
    },

    {
        path: "destinationbooking/:id",
        element: <Layout><TopDestBooking /></Layout>
    },

    {
        path: "testimonial",
        element: <Layout><TestimonialPage /></Layout>
    },

    {
        path: "careers",
        element: <Layout><CareersPage /></Layout>
    },

    {
        path: "planyourvisit",
        element: <Layout><PlanYourVisit /></Layout>
        // element: !isMobile ? <Layout><PlanYourVisit /></Layout> : <Navigate to="/accountflow" />
    },

    {
        path: "rulespage",
        element: <Layout><RulesAndReg /></Layout>
    },

    {
        path: "jobs",
        element: <Layout><CareersDetails /></Layout>
    },

    {
        path: "guestservice",
        element: <Layout><GuestServicePage /></Layout>
    },

    {
        path: "comingsoon",
        element: <Layout><ComingSoonPage /></Layout>
    },

    {
        path: "job/:id",
        element: <Layout><Detail /></Layout>
    },

    {
        path: "independenceday",
        element: <Layout><IndependencePage /></Layout>
    },

    {
        path: "booking",
        // element: !isMobile ? <Layout><BookingPage /></Layout> : <Navigate to="/accountflow" />
        element: <Layout>
                    <BookingDataProvider>
                        <BookingPage />
                    </BookingDataProvider>
                </Layout>
    },

    {
        path: "reviewbooking",
        element: <Layout><ReviewBookingPage /></Layout>
    },

    {
        path: "signup",
        element: <Layout><SignupPage/></Layout>
    },

    {
        path: "verify-otp",
        element: <Layout><OtpVerifyPage/></Layout>
    },

    {
        path: "login",
        element: <Layout><LoginPage/></Layout> 
    },

    {
        path: "forgot-password",
        element: <Layout><ForgotPasswordPage /></Layout>
    },

    {
        path: "reset-password",
        element: <Layout><ResetPwdPage /></Layout>
    },

    {
        path: "accountsuccess",
        element: <Layout><AccountSuccessPage /></Layout>
    },

    {
        path: "groupdetails",
        element: <Layout><GroupBookingPage /></Layout>
    },

    {
        path: "sentrequest",
        element: <Layout><SentRequest /></Layout>
    },

    {
        path: "accountflow",
        element: isLoggedIn ? <Layout><AccountFlow /></Layout> : <Navigate to="/login" />
    },

    {
        path: "paymentfailed/:id",
        // path: "paymentfailed",
        element: <Layout><PaymentPage /></Layout>
    },
    {
        path: "paymentsuccess/:id",
        // path: "paymentsuccess",
        element: <Layout><PaymentSuccess /></Layout>
    },

    {
        path: "about-us",
        element: <Layout><AboutusPage /></Layout>
    },

    {
        path: "dresscode",
        element: <Layout><DressCodePage /></Layout>
    },

    {
        path: "safety-hygiene",
        element: <Layout><SafetyHygienePage /></Layout>
    },

    {
        path: "parktimings",
        element: <Layout><ParkTimingsPage /></Layout>
    },

    {
        path: "privacy-policy",
        element: <Layout><PrivacyPolicyPage /></Layout>
    },

    {
        path: "terms&conditions",
        element: <Layout><TermsConditionPage /></Layout>
    },

    {
        path: "cancellation-refund",
        element: <Layout><CancellationPage /></Layout>
    },

    {
        path: "greendesign",
        element: <Layout><GreenDesignPage /></Layout>
    },

    {
        path: "heightrequirement",
        element: <Layout><HeightRequirementPage /></Layout>
    },

    {
        path: "contact-us",
        element: <Layout><ContactUsPage /></Layout>
    },

    {
        path: "refund-return",
        element: <Layout><RefundReturn /></Layout>
    },

    {
        path: "services",
        element: <Layout><ServicesPage /></Layout>
    },

    {
        path: "environment",
        element: <Layout><EnvironmentPage /></Layout>
    },

    {
        path: "custom-booking",
        // element: !isMobile ? <Layout><CustomBookingPage /></Layout> : <Navigate to="/accountflow" />
        element: <Layout><CustomBookingPage /></Layout>
    },

    {
        path: "packages",
        // element: !isMobile ? <Layout><PackagesPage /></Layout> : <Navigate to="/accountflow" />
        element: <Layout><PackagesPage /></Layout>
    }

]


function AppRoutes({currentImages}) {
    let isLoggedIn = false, isMobile = null;

    if (getToken("token")) {
        isLoggedIn = true

        let customer = JSON.parse(localStorage.getItem('customerData'));
        isMobile = !customer?.phone
    } else {
        isLoggedIn = false
    }

    const routes = useRoutes(routesList(isLoggedIn, isMobile, currentImages));
    return routes;
}

export default AppRoutes;
