import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './destislider.css';
import { Navigation } from 'swiper/modules';
import { Image } from 'antd';
import { Container } from 'react-bootstrap';
import destinations from '../../../common/Destination.json';
import { useParams } from 'react-router-dom';

const DestiSlider = () => {
    const { id } = useParams();
    const destination = destinations.find((dest) => dest.id === parseInt(id));

    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 425);

    React.useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 425);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Container fluid={true} className='img-container '>
            <div>
                {!isMobile ? (
                    <Swiper
                        slidesPerView={'auto'}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                        modules={[Navigation]}
                        className="destislider"
                    >
                        {destination.slide.map((slideImage, index) => (
                            <SwiperSlide key={index} className="destination-slide">
                                <div className='w-100 h-100 p-2'>
                                    <div className='custom-slide-images'>
                                        <Image
                                            src={require (`../../../assets/images/topdestination/destinationimg/${slideImage}`)}
                                            alt={`Slide Image ${index + 1}`}
                                            preview={false}
                                            style={{ borderRadius: '16px' }}                                           
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                        <div className="swiper-button-prev" />
                        <div className="swiper-button-next" />
                    </Swiper>
                ) : (
                    destination.slide.map((slideImage, index) => (
                        <div key={index} className='p-1'>
                            <div>
                                <Image
                                    src={require (`../../../assets/images/topdestination/destinationimg/${slideImage}`)}
                                    alt={`Slide Image ${index + 1}`}
                                    preview={false}
                                    style={{ borderRadius: '16px' }}
                                />
                            </div>
                        </div>
                    ))
                )}
            </div>
        </Container>
    );
};

export default DestiSlider;
