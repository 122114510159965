import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Button, Form, Input, message } from 'antd';
import './newsandupdates.css'
import FooterImage from '../../FooterImage/FooterImage';
import { makeRequest } from '../../../services/api';

const NewsAndUpdates = () => {
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        const payload = {
            email: values.email
        }

        console.log(values, "email")
        try {
            const response = await makeRequest('post', '/news/add', payload)

            if(response.statusCode == 200) {
                message.success('Submit success!');
            }
        } catch (error) {
            console.log(error, "CHECK THE ERROR")
        }
    };

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

    return (
        <Container
            fluid={true}
            className='pt-5'
        >
            <Row className='align-items-center justify-content-center'>
                <Col xxl={6} xl={6} lg={6} md={8} sm={10} xs={12} className='text-center'>
                    <p className='fs-28 mb-1 fw-400 text-black sub-header'>Latest News & Updates</p>
                    <p className={`${window?.innerWidth > 834.98 ? 'fs-40' : window?.innerWidth <= 425 ? 'fs-28' : 'fs-36'} fw-800 text-black py-1 mb-0 header`}>News & Updates</p>
                    <p className='fs-18 mb-0 fw-400 text-black'>Sign up and be the first to know the latest news, offers, promotions and special events of our Water World.</p>
                </Col>
            </Row>

            <Row className='align-items-center justify-content-center py-5'>
                <Col xxl={window?.innerWidth > 1708 ? 4 : 5} xl={5} lg={7} md={9} sm={10} xs={12} className='text-center'>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        requiredMark={false}
                        className='newupdateForm'
                    >
                        <Row>
                            <Col xxl={9} xl={9} lg={8} md={8} sm={12} xs={12}>
                                <Form.Item
                                    name="email"
                                    label=""
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter an email before proceeding'
                                        },
                                        {
                                            type: 'email',
                                            message: 'Please enter an valid email'
                                        }
                                    ]}
                                >
                                    <Input placeholder="Email" size='large' className="text-black custom-placeholder-input" style={{ borderColor: 'black'}} id="url" aria-required="true" type="text" />
                                </Form.Item>
                            </Col>
                            <Col xxl={3} xl={3} lg={4} md={4} sm={12} xs={12}>
                                <Form.Item>
                                    <Button type="default" size='large' className='btn-danger booking-btn w-100 fw-600' htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

            <FooterImage />

        </Container>
    )
}

export default NewsAndUpdates
