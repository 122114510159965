import React, { useEffect, useState } from 'react'
import { Row, Col } from "react-bootstrap";
import { Button, Image, message } from "antd";
import "./AdditionalServices.css"
import TextArea from 'antd/es/input/TextArea';
import Photograher from "../../../assets/images/custom-booking/additional/img-photographer.webp";
import Snorkeling from "../../../assets/images/custom-booking/additional/img-snorkeling-gear.webp";
import Trekking from "../../../assets/images/custom-booking/additional/img-trekking-gear.webp";
import WaterSports from "../../../assets/images/custom-booking/additional/img-watersports-equipment.webp";
import { CheckCircleFilled } from '@ant-design/icons';
import useCustomExperience from '../../../hooks/common/useCustomExperience';

const services = [
    {
        id: 'Photograph',
        title: "Photographer",
        list: [
            {
                id: 1,
                name: 'Photographer',
                price: "₹2000 per day (capture your experience professionally)",
                image: Photograher
            }
        ]
    },
    {
        id: "Equipment",
        title: "Equipment Rentals",
        list: [
            {
                id: 2,
                name: "Snorkeling gear",
                price: "₹1000",
                image: Snorkeling
            },
            {
                id: 3,
                name: "Trekking gear ",
                price: "₹500",
                image: Trekking
            },
            {
                id: 4,
                name: "Water sports equipment ",
                price: "₹1500",
                image: WaterSports
            }
        ]
    }
]

const AdditionalServices = ({setCustomDetails, setActiveKey, customDetails}) => {

    const [addservices, setAddServices] = useState(customDetails.additionalServices || []);
    const [specialRequests, setSpeicalRequests] = useState(customDetails.specialRequests || '');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 530);
    const { createCustomBooking, isLoading } = useCustomExperience(); 

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 530);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleClick = (id, name) => {
        setAddServices((prev) =>
            prev.some((item) => item.id === id)
                ? prev.filter((item) => item.id !== id)
                : [...prev, { id, name }]
        );
    };   
    
    useEffect(() => {
        setCustomDetails((prevDetails) => ({
            ...prevDetails,
            additionalServices: addservices,
            specialRequests: specialRequests,
        }));
    }, [addservices, specialRequests, setCustomDetails]);

    const handleSpecialRequestChange = (e) => {
        setSpeicalRequests(e.target.value);
    };

    const handleBack = () => {
        setActiveKey('6')
    }

    const handleSubmit = async () => {
        if (!customDetails || Object.keys(customDetails).length === 0) {
            message.error("Please complete all required fields before submitting.");
            return;
        }

        const response = await createCustomBooking(customDetails);
        
        if (response) {
            message.success("Booking confirmed!");
        }
    };
    
    return (
        <div className='additional-services'>
            <h4 className='fs-24 fw-700 py-2'>Additional Services</h4>

            <Row>
                {services.map((category) => (
                    <Col
                        key={category.id}
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="pb-4"
                    >
                        <h6 className="fs-18 fw-700 pb-2">{category.title}</h6>

                        <Row>
                            {category.list.map((item) => (
                                <Col 
                                    key={item.id}
                                    xxl={6}
                                    xl={6}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className="pb-3"
                                >                       
                                <div 
                                    className='p-3 d-flex gap-3 justify-content-between'
                                    style={{
                                        background: "#F8F8F9",
                                        borderRadius: '24px',
                                        border: addservices.some((selectedItem) => selectedItem.id === item.id)
                                            ? "2px solid #000000"
                                            : "1px solid transparent",
                                    }}
                                    onClick={() => handleClick(item.id, item.name)}
                                >
                                    <div className="d-flex flex-column">
                                        <CheckCircleFilled
                                            className="check-circle"
                                            style={{
                                                color: addservices.some((selectedItem) => selectedItem.id === item.id)
                                                    ? "#2263E3"
                                                    : "transparent",
                                                border: addservices.some((selectedItem) => selectedItem.id === item.id)
                                                    ? "1px solid transparent"
                                                    : "1px solid #303136",
                                            }}
                                        />
                                        <p className="fs-14 fw-700 destination-name my-2">
                                            {item.name}
                                        </p>
                                        <p className='fs-14 fw-400'>
                                            {item.price}
                                        </p>
                                    </div>

                                    <div className="services-image-container">
                                        <Image
                                            src={item.image}
                                            alt={item.name}
                                            preview={false}
                                            className="select-services-img"
                                        />
                                    </div>
                                </div>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                ))}
            </Row>

            <h4 className='fs-24 fw-700 py-3'>Special Requests</h4>

            <TextArea 
                style={{resize: "none", height: 225, borderRadius: '16px'}}
                maxLength={300}
                className='py-3'
                placeholder='Please specify any special requests such as birthday surprises, picnic setups, etc.'
                value={specialRequests}
                onChange={handleSpecialRequestChange}
            />

            <Row className='d-flex justify-content-end pt-4'>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='text-end'>
                    <Button
                        type="default"
                        className="fs-14 fw-600 custom-back-btn m-1"
                        size="large"
                        style={{borderColor: '#272727'}}
                        onClick={handleBack}
                    >
                        Back
                    </Button>
                
                    <Button
                        type="primary"
                        className="fs-14 fw-600 custom-book-btn m-1"
                        size="large"
                        loading={isLoading}
                        onClick={handleSubmit}
                    >
                        Book Now
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default AdditionalServices
