import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import BookingSlider from '../../components/OfferBooking/BookingSlider/BookingSlider'
import NewsAndUpdates from '../../components/homepage/NewsAndUpdates/NewsAndUpdates'
import BookNow from '../../components/OfferBooking/BookNow/BookNow'
import BookingBanner from '../../components/OfferBooking/BookingBanner/BookingBanner'

const OfferBooking = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  return (
    <Container fluid={true} className="p-0">
        <BookingBanner />
        <BookingSlider/>
        <BookNow/>
        <NewsAndUpdates/>
    </Container>
  )
}

export default OfferBooking
