import React, { useState, useEffect } from 'react';
import './PaymentSuccessSec.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Button, Divider, Image, message } from 'antd';
import Lottie from 'lottie-react';
import successAnimation from '../../../common/successanimation.json';
import SuccessConfetti from '../../../assets/videos/successconfetti.gif';
import useRetail from '../../../hooks/useRetail';
import { useParams } from 'react-router-dom';
import "jspdf-autotable";
import QRCode from 'qrcode';
import useBookingPdf from '../../../hooks/common/useBookingPdf';

const ticketData = [
  {
      id: 1,
      type: "Adults",
      description: "Height above 140 cm or 4 feet 7 inches.",
      count: 0,
      price: 0,
  },
  {
      id: 2,
      type: "Children",
      description: "Height between 90 cm and 140 cm or 3 feet and 4 feet 7 inches.",
      count: 0,
      price: 0,
  },
  {
      id: 3,
      type: "Senior",
      description: "Aged above 60 years. Seniors required to show proof of age.",
      count: 0,
      price: 0,
  },
  {
      id: 4,
      type: "Disabled",
      description: "For individuals with a permanent disability.",
      count: 0,
      price: 0,
  }
];

const PaymentSuccessSec = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);
  const [bookingData, setBookingData] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [qrCode, setQrCode] = useState(null);
  const [tickets, setTickets] = useState(ticketData);
  const [downloadPayload, setDownloadPayload] = useState(null);
  
  const { getBookingDetails, priceMeta} = useRetail();
  const { id } = useParams();
  const { downloadPdf} = useBookingPdf()

  useEffect(() => {
    if (id) {
      const fetchBookingData = async () => {
        const data = await getBookingDetails(id);
        if (data) {
          setBookingData(data);
        } else {
          message.error('Failed to load booking details');
        }
      };
      fetchBookingData();
    }
  }, [id]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const total = 
    bookingData?.booking?.ticketMeta?.adult + 
    bookingData?.booking?.ticketMeta?.child + 
    bookingData?.booking?.ticketMeta?.senior + 
    bookingData?.booking?.ticketMeta?.disabled;

  const mealTotal = bookingData?.booking?.ticketMeta?.food?.reduce(
    (acc, item) => acc + item.count, 0
  );
  
  const merchandiseTotal = bookingData?.booking?.ticketMeta?.merchandise?.reduce(
    (acc, item) => acc + item.count, 0
  );

  useEffect(() => {
    if (bookingData) {
      const generateQRCode = async () => {
        const qrCodeData = `Booking ID: ${bookingData?.booking?.bookingId},
                            Name: ${bookingData?.booking?.fullName},
                            Booking Date: ${bookingData?.booking?.bookingDate},                           
                            Phone Number: ${bookingData?.booking?.phoneNumber},
                            Total Amount Paid: ₹${bookingData?.booking?.amount}`;

        try {
          const qrCodeBase64 = await QRCode.toDataURL(qrCodeData, {
            width: 160,
            margin: 2,
          });
          setQrCode(qrCodeBase64);
        } catch (error) {
          console.error('Error generating QR code:', error);
        }
      };

      generateQRCode();
    }
  }, [bookingData]);

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/').map(Number);
    
    const fullYear = year < 100 ? 2000 + year : year;
    const date = new Date(fullYear, month - 1, day);

    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  };

  const isWeekend = (dateString) => {
      const date = parseDate(dateString);
      const day = date.getDay();

      return day === 0 || day === 6;
  };

  useEffect(() => {
    if (priceMeta && bookingData?.booking?.bookingDate) {
      const isWeekendDay = isWeekend(bookingData?.booking?.bookingDate);
      const priceType = isWeekendDay ? 'weekendPrice' : 'weekdaysPrice';
  
      setTickets((prevTickets) =>
        prevTickets.map((ticket) => {
          let price = 0;
          switch (ticket.type) {
            case 'Adults':
              price = priceMeta[priceType]?.adult || 0;
              break;
            case 'Children':
              price = priceMeta[priceType]?.child || 0;
              break;
            case 'Senior':
              price = priceMeta[priceType]?.senior || 0;
              break;
            case 'Disabled':
              price = priceMeta[priceType]?.disabled || 0;
              break;
            default:
              break;
          }
          console.log(`Ticket: ${ticket.type}, Price: ${price}`);
          return { ...ticket, price: price || 0 };
        })
      );
    }
  }, [priceMeta, bookingData?.booking?.bookingDate]);  

  useEffect(() => {
    if (bookingData?.booking) {
      const { booking } = bookingData;
      const totalFoodCount = booking?.ticketMeta?.food?.reduce((sum, item) => sum + item.count, 0);
      const merchandiseTotalCount = booking?.ticketMeta?.merchandise?.reduce((sum, item) => sum + item.count, 0);
  
      const categoryMapping = {
        Adults: "adult",
        Children: "child",
        Senior: "senior",
        Disabled: "disabled"
      };
  
      const items = [
        ...tickets.map(ticket => {
          const categoryKey = categoryMapping[ticket.type];
          const ticketCount = booking?.ticketMeta?.[categoryKey] || 0;
          return {
            name: ticket.type, 
            qty: ticketCount, 
            price: ticket.price, 
            totalPrice: ticket.price * ticketCount
          };
        }).filter(ticket => ticket.qty > 0),
        ...(booking?.ticketMeta?.food?.map(item => ({
          name: item.foodName,
          qty: item.count,
          price: item.price,
          totalPrice: item.totalPrice
        })) || []),
        ...(booking?.ticketMeta?.merchandise?.map(item => ({
          name: item.merchandiseName,
          qty: item.count,
          price: item.price,
          totalPrice: item.totalPrice
        })) || [])
      ];
  
      setDownloadPayload({
        bookingId: booking?.bookingId,
        fullName: booking?.fullName,
        location: booking?.location,
        gstNo: booking?.gstNo,
        bookingDate: booking?.bookingDate,
        totalAmount: booking?.amount,
        ticketCount: booking?.ticketMeta?.totalTicketCount,
        foodCount: totalFoodCount,
        merchandiseCount: merchandiseTotalCount,
        email: booking?.email,
        phone: booking?.phoneNumber,
        items: items,
        subTotal: booking?.totalAmount,
        discount: booking?.discountAmount,
        totalGst: booking?.gstAmount,
        totalBefore: booking?.totalBeforeTax,
        amountPayable: booking?.amount
      });
    }
  }, [bookingData, tickets]);  

  const handlePrintTickets = async () => {
    try {
        
        if (downloadPayload) {
            const response = await downloadPdf(downloadPayload);

            if (response && response.pdfUrl) {
                const newWindow = window.open(response.pdfUrl, '_blank');
                
                if (newWindow) {
                    newWindow.onload = () => {
                        newWindow.print();
                    };
                } else {
                    message.error("Failed to open PDF for printing.");
                }
            } else {
                message.error("Failed to generate the PDF.");
            }
        }
    } catch (error) {
        message.error("Error while printing the ticket.");
    }
  };

  const handleDownloadPdf = async () => {
    try {
      if(downloadPayload) {
        const response = await downloadPdf(downloadPayload);

        if(response) {
          message.success("CHECK THE PDF")
        }
      }

    } catch (error) {
      message.error("NEW ERROR")
    }
  }

  const ticketTypeMapping = {
    adult: "Adults",
    child: "Children",
    senior: "Senior",
    disabled: "Disabled"
  };

  return (
    <div style={{background: '#0675F6'}}>
      <div>
        <Container className="py-5">
          <Row className='d-flex justify-content-center align-items-center'>
            <Col xxl={8} xl={8} lg={8} md={10} sm={11} xs={11} style={{background: '#FFFFFF', borderRadius: '24px'}}>
              <Row className='position-relative successs-container pb-5 mb-5'>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='text-center'>
                  <div className="success-container">
                    <div className="success-gif-container">
                      <Image
                        src={SuccessConfetti}
                        alt="Success Confetti"
                        preview={false}
                      />
                    </div>

                    <div className="text-center success-content">
                      <div className="tick-animation">
                        <Lottie animationData={successAnimation} loop={true} />
                      </div>

                      <p className={`${window?.innerWidth > 834.98 ? 'fs-36' : window?.innerWidth <= 425 ? 'fs-24' : 'fs-32'} fw-700 text-black mb-0`}>Booking Confirmed!</p>
                      <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black mb-0`}>Thank you for your purchase!</p>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className='details-mob p-4 mt-4' >
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div id="ticket-info">
                    <Row className='py-4' >
                      <Col xxl={9} xl={9} lg={9} md={8} sm={12} xs={12}>
                        <div className='qr-mobile'>
                            <div>
                              {qrCode && (
                                  <Image
                                    src={qrCode}
                                    alt="Booking QR Code"
                                    preview={false}
                                    style={{
                                      border: '3px solid #0066cc',
                                      borderRadius: '8px',
                                      width: '170px',
                                      height: '170px',
                                    }}
                                  />
                              )}
                            </div>
                            <div className=' pt-3 text-center'>
                              <h5 className='fs-16 fw-400'>Ticket ID:</h5>
                              <p className='fs-18 fw-700'>#{bookingData?.booking?.bookingId || "N/A"}</p>
                            </div>
                          </div>
                        <Row>
                          <Col xxl={4} xl={4} lg={6} md={6} sm={6} xs={6} className='pb-2'>
                            <h5 className='fs-16 fw-400'>Date:</h5>
                            <p className='fs-16 fw-700'>{bookingData?.booking?.bookingDate || "N/A"}</p> 
                          </Col>

                          <Col xxl={4} xl={4} lg={6} md={6} sm={6} xs={6} className='pb-2'>
                            <h5 className='fs-16 fw-400'>Visitor name:</h5>
                            <p className='fs-16 fw-700'>{bookingData?.booking?.fullName || "N/A"}</p>
                          </Col>

                          <Col xxl={4} xl={4} lg={6} md={6} sm={6} xs={6} className='pb-2'>
                            <h5 className='fs-16 fw-400'>Total amount to be paid:</h5>
                            <p className='fs-16 fw-700'>₹ {(bookingData?.booking?.amount || 0)}</p>
                          </Col>
                        
                          <Col xxl={4} xl={4} lg={6} md={6} sm={6} xs={6} className='pb-2'>
                            <h5 className='fs-16 fw-400'>Tickets:</h5>
                            <p className='fs-16 fw-700'>{total || 0} tickets - ₹ {(bookingData?.booking?.ticketTotal || 0).toFixed(2)}</p>
                          </Col>

                          <Col xxl={4} xl={4} lg={6} md={6} sm={6} xs={6} className='pb-2'>
                            <h5 className='fs-16 fw-400'>Meals:</h5>
                            <p className='fs-16 fw-700'>{mealTotal || 0 } items - ₹ {(bookingData?.booking?.foodTotal || 0).toFixed(2)}</p>
                          </Col>

                          <Col xxl={4} xl={4} lg={6} md={6} sm={6} xs={6} className='pb-2'>
                            <h5 className='fs-16 fw-400'>Merchandise:</h5>
                            <p className='fs-16 fw-700'>{merchandiseTotal || 0 } items - ₹ {(bookingData?.booking?.merchadiseTotal || 0).toFixed(2)}</p>
                          </Col>

                          <Col xxl={4} xl={4} lg={6} md={6} sm={6} xs={6} className='pb-2'>
                            <h5 className='fs-16 fw-400'>Email:</h5>
                            <p className='fs-16 fw-700 text-truncate'>{bookingData?.booking?.email || "N/A"}</p>
                          </Col>
                        
                          <Col xxl={4} xl={4} lg={6} md={6} sm={6} xs={6} className='pb-2'>
                            <h5 className='fs-16 fw-400'>Contact Info:</h5>
                            <p className='fs-16 fw-700'>{bookingData?.booking?.phoneNumber || "N/A"}</p>
                          </Col>

                          <Col xxl={4} xl={4} lg={6} md={6} sm={6} xs={6} className='pb-2'>
                            <h5 className='fs-16 fw-400'>Pincode:</h5>
                            <p className='fs-16 fw-700'>{bookingData?.booking?.pincode || "N/A"}</p>
                          </Col>                  
                        </Row>
                      </Col>

                      <Col xxl={3} xl={3} lg={3} md={4} className=' d-flex justify-content-end'>
                        <div className='qr-web'>
                          <div>
                            {qrCode && (
                                <Image
                                  src={qrCode}
                                  alt="Booking QR Code"
                                  preview={false}
                                  style={{
                                    border: '3px solid #0066cc',
                                    borderRadius: '8px',
                                    width: '170px',
                                    height: '170px',
                                  }}
                                />
                            )}
                          </div>
                          <div className=' pt-3 text-center'>
                            <h5 className='fs-16 fw-400'>Ticket ID:</h5>
                            <p className='fs-18 fw-700'>#{bookingData?.booking?.bookingId || "N/A"}</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <Row>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} className="text-center mb-3">
                      <Button
                        className="fs-16 fw-600 booking-btn"
                        size="large"
                        style={{ background: 'black', color: 'white', borderColor: 'black', width: '100%' }}
                        onClick={handleDownloadPdf}
                        disabled={isDownloading}
                      >
                        Download E-Tickets
                      </Button>
                    </Col>

                    <Col xxl={3} xl={3} lg={3} md={4} sm={12} xs={12} className="text-center mb-3">
                      <Button
                        className="fs-16 fw-600 booking-btn"
                        size="large"
                        style={{ borderColor: 'black', width: '100%' }}
                        onClick={handlePrintTickets}
                      >
                        Print Tickets
                      </Button>
                    </Col>
                  </Row> 
                </Col>
              </Row>

              <div className='details-mob px-4'>
                <Divider
                  style={{background: '#A0A1AB'}}
                />
              </div>

              <Row className='d-flex align-items-center justify-content-center px-4 details-mob'>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className={`${window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-24'} fw-700 text-black`}>
                      Ticket details ({bookingData?.booking?.ticketMeta?.totalTicketCount || 0})
                    </h5>                     
                    <span className={`${window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-24'} fw-700 text-black`}>
                      ₹ {(bookingData?.booking?.ticketTotal || 0).toFixed(2)}
                    </span>
                  </div>
                  <div>
                    {Object.entries(bookingData?.booking?.ticketMeta || {})
                      .filter(([key, value]) => 
                        key !== "totalTicketCount" && key !== "food" && key !== "merchandise" && value > 0
                      )
                      .map(([key, value], index) => {
                        const mappedType = ticketTypeMapping[key];
                        const ticket = tickets.find(ticket => ticket.type === mappedType);
                        
                        return (
                          <Row key={index} className="mt-4">
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                              <h6 className={`${window?.innerWidth > 834.98 ? 'fs-20' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-20'} fw-700 text-black`}>
                                {mappedType} Ticket
                              </h6>
                              <div className="d-flex justify-content-between align-items-center">
                                <h5 className={`${window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'} fw-700 text-black`}>
                                  ₹{(ticket?.price || 0).toFixed(2)} x {value}
                                </h5>
                                <h5 
                                  className={`${window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'} fw-400`}
                                  style={{color: '#60616C'}}
                                >
                                  ₹{((ticket?.price || 0) * value).toFixed(2)}
                                </h5>
                              </div>
                            </Col>
                          </Row>
                        );
                      })}
                  </div>
                </Col>
              </Row>

              <div className='details-mob px-4'>
                <Divider
                  style={{background: '#A0A1AB'}}
                />
              </div>

              <Row className='d-flex align-items-center justify-content-center px-4 details-mob'>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h5 className={`${window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-24'} fw-700 text-black`}>
                      Meals details ({mealTotal})
                    </h5>
                    <span className={`${window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-24'} fw-700 text-black`}>
                      ₹ {(bookingData?.booking?.foodTotal || 0).toFixed(2)}
                    </span>
                  </div>

                  <div>
                    {bookingData?.booking?.ticketMeta?.food?.map((item, index) => (
                      <Row key={index} className='mt-4'>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                          <h6 className='fs-20 fw-700 text-black'>{item.foodName}</h6>
                          <div className='d-flex justify-content-between align-items-center'>
                            <h5 className='fs-24 fw-700 text-black'>₹{item.price.toFixed(2)} x {item.count}</h5>
                            <h5 className='fs-24 fw-400' style={{ color: '#60616C' }}>₹{(item.totalPrice || 0).toFixed(2)}</h5>
                          </div>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </Col>
              </Row>

              <div className='details-mob px-4'>
                <Divider
                  style={{background: '#A0A1AB'}}
                />
              </div>

              <Row className='d-flex align-items-center justify-content-center px-4 details-mob'>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h5 className={`${window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-24'} fw-700 text-black`}>
                      Merchandise details ({merchandiseTotal})
                    </h5>
                    <span className={`${window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-24'} fw-700 text-black`}>
                      ₹ {(bookingData?.booking?.merchadiseTotal || 0).toFixed(2)}
                    </span>
                  </div>

                  <div>
                    {bookingData?.booking?.ticketMeta?.merchandise?.map((item, index) => (
                      <Row key={index} className='mt-4'>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                          <h6 className='fs-20 fw-700 text-black'>{item.merchandiseName}</h6>
                          <div className='d-flex justify-content-between align-items-center'>
                            <h5 className='fs-24 fw-700 text-black'>₹{item.price.toFixed(2)} x {item.count}</h5>
                            <h5 className='fs-24 fw-400' style={{ color: '#60616C' }}>₹{(item.totalPrice || 0).toFixed(2)}</h5>
                          </div>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </Col>
              </Row>

              <div className='details-mob px-4'>
                <Divider
                  style={{background: '#A0A1AB'}}
                />
              </div>

              <div className='d-flex justify-content-between align-items-center details-mob px-4'>
                <p className='fs-20 fw-700 text-black mb-0'>Total Amount:</p>
                <p className='fs-20 fw-700 text-black mb-0'>₹{(bookingData?.booking?.totalAmount || 0)}</p>
              </div>
              <div className='d-flex justify-content-between align-items-center details-mob px-4'>
                <p className='fs-20 fw-700 text-black mb-0'>Discount:</p>
                <p className='fs-20 fw-700 text-black mb-0'>₹{(bookingData?.booking?.discountAmount.toFixed(2) || 0)}</p>
              </div>
              <div className='d-flex justify-content-between align-items-center details-mob px-4'>
                <p className='fs-20 fw-700 text-black mb-0'>Gst Amount:</p>
                <p className='fs-20 fw-700 text-black mb-0'>₹{(bookingData?.booking?.gstAmount || 0)}</p>
              </div>
              <div className='d-flex justify-content-between align-items-center details-mob px-4'>
                <p className='fs-20 fw-700 text-black'>Total Amount Before Tax:</p>
                <p className='fs-20 fw-700 text-black'>₹{(bookingData?.booking?.totalBeforeTax || 0)}</p>
              </div>
              <div className='d-flex justify-content-between align-items-center details-mob mb-4 px-4'>
                <h5 className='fs-24 fw-700 text-black'>Total amount paid:</h5>
                <h5 className='fs-28 fw-700 text-black'>₹ {(bookingData?.booking?.amount || 0)}</h5>
              </div>
            
              <Row className='d-flex justify-content-center align-items-center p-3' style={{ background: '#FAD657'}}>
                <Col xxl={8} xl={8} lg={7} md={7} sm={12} xs={12} className='success-offer text-start'>
                  <h3 className={`${window?.innerWidth > 834.98 ? 'fs-48' : window?.innerWidth <= 425 ? 'fs-32' : 'fs-44'} fw-800 mb-0`}>10% OFF</h3>
                  <h3 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 mb-0`}> on next booking</h3>
                  <p className='fs-14 fw-400'>Enjoy your holidays with us!</p>
                </Col>
                <Col xxl={3} xl={3} lg={5} md={5} sm={12} xs={12} className='text-center'>
                  <div className="code-container">
                    <p className="fs-14 fw-600 code-label">YOUR CODE</p>
                    <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 mb-0 p-3`}>DRW256816</h4>
                  </div>
                  <p className='fs-14 fw-400 mt-2'>Enter this code on the site to get FREE Rides</p>
                </Col>
              </Row>
                  
            </Col>
          </Row>
        </Container>
        </div>
    </div>

  );
};

export default PaymentSuccessSec;
