import React, { useEffect, useState } from 'react';
import './offerpage.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Button, Image } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import useCoupons from '../../hooks/common/useCoupon';
import { useData } from '../../context/DataContext';

const OfferPage = ({ selectedCategory }) => {
    const navigate = useNavigate();
    const { coupons } = useCoupons();
    const { setSharedData } = useData();
    const [couponPackage, setCouponPackage ] = useState();
    const filteredOffers = selectedCategory === 'all'
        ? couponPackage
        : couponPackage?.filter(offer => offer.couponType === selectedCategory);

    useEffect(() => {
        if(coupons) {
            const couponsDetail = coupons.map((coupon) => ({
                key: coupon?._id,
                img: coupon?.mediaId?.key || "",
                couponCode: coupon?.couponCode,
                heading: coupon?.offerName || "",
                couponType: coupon?.couponType || '',
                headingColor: coupon?.meta_data?.foregroundColor || "#000",
                subHeading: coupon?.description 
                    ? coupon?.description.replace(/<\/?p>/g, "").replace(/<[^>]+>/g, "")
                    : "",
                offerTxt: coupon?.subTitle || "",
                offerSubTxt: coupon?.subDescription
                    ? coupon?.subDescription.replace(/<\/?p>/g, "").replace(/<[^>]+>/g, "")
                    : "",
                bgLight: coupon?.meta_data?.backgroundColor || "default-bg",
                hoverGradient: coupon?.meta_data?.backgroundColor && coupon?.meta_data?.foregroundColor
                    ? `linear-gradient(180deg, ${coupon?.meta_data?.backgroundColor} 76.54%, ${coupon?.meta_data?.foregroundColor} 100%)`
                    : ""
            }));
            setCouponPackage(couponsDetail)
        }
    },[coupons])

    const handleNavigate = (coupon) => {
        setSharedData({ couponCode: coupon });
        navigate('/booking')
    };

    return (
        <Container>
            <Row className='d-flex align-items-stretch offer-container'>
                {filteredOffers?.map((item, index) =>
                    <Col xxl={4} xl={4} lg={6} md={10} sm={12} xs={12} key={index} className='my-3'>
                        <div 
                            className={`h-100 offer-card ${item?.bgLight} p-4`} 
                            style={{ borderRadius: '24px', cursor: 'pointer', transition: 'background 0.3s ease-in-out', background: item?.bgLight }}
                            onMouseEnter={(e) => e.currentTarget.style.background = item.hoverGradient}
                            onMouseLeave={(e) => e.currentTarget.style.background = item?.bgLight}
                        >
                            <div className='image-container'>
                                <Image
                                    src={item.img}
                                    alt={item.img}
                                    preview={false}
                                    className="offer-image"
                                    style={{borderRadius: '16px'}}
                                />
                            </div>
                            <div className='mt-3' style={{minHeight: '350px'}}>
                                <h4 
                                    className='fs-28 fw-700 pb-1' 
                                    style={{ color: item.headingColor }}
                                >
                                    {item.heading}
                                </h4>
                                <p className='fs-18 fw-400 text-black'>{item.subHeading}</p>
                                <h6 className='fs-20 fw-700 text-black pt-3'>{item.offerTxt}<span style={{color: item?.headingColor}}>{item.couponCode}</span></h6>
                                <p className='fs-16 fw-400 text-black'>{item.offerSubTxt}</p>
                            </div>

                            <div className=''>
                                <Button 
                                    type="default"
                                    size="large"
                                    className="fs-16 fw-700 learn-offer-package-btn"
                                    onClick={() => navigate(`/offerbooking/${item.key}`)}
                                >
                                    Learn More <ArrowRightOutlined />
                                </Button>
                                <Button 
                                    type='primary'
                                    size='large'
                                    className='fs-16 fw-700 home-offer-package-btn'
                                    onClick={() => handleNavigate(item.couponCode)}
                                >
                                    Book Now
                                    <ArrowRightOutlined />
                                </Button>
                            </div>
                        </div>
                    </Col>
                )}               
            </Row>
        </Container>
    )
}

export default OfferPage;