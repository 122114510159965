import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./CustomBookingPage.css";
import ChooseExperience from "../../components/CustomBooking/ChooseExperience/ChooseExperience";
import SelectDestinations from "../../components/CustomBooking/SelectDestinations/SelectDestinations";
import StayOptions from "../../components/CustomBooking/StayOptions/StayOptions";
import FoodPreferences from "../../components/CustomBooking/FoodPreferences/FoodPreferences";
import Transportation from "../../components/CustomBooking/Transportation/Transportation";
import PlanYourItinerary from "../../components/CustomBooking/PlanYourItinerary/PlanYourItinerary";
import AdditionalServices from "../../components/CustomBooking/AdditionalServices/AdditionalServices";
import NewsAndUpdates from "../../components/homepage/NewsAndUpdates/NewsAndUpdates";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

const CustomBookingPage = () => {
    const [activeKey, setActiveKey] = useState("1");
    const [tabPosition, setTabPosition] = useState("left");
    const [customDetails, setCustomDetails] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            message.error("You need to log in first.");
            navigate("/login");
        }
    }, [navigate]);

    const handleTabChange = (nextKey) => {
        if (parseInt(nextKey) > parseInt(activeKey)) {
            if (activeKey === "1") {
                if (
                    !customDetails?.name ||
                    !customDetails?.email ||
                    !customDetails?.phone ||
                    !customDetails?.tripDays || 
                    !customDetails?.date ||
                    !customDetails?.startDate ||
                    !customDetails?.endDate ||
                    !customDetails?.noOfPeople
                ) {
                    message.error("Please fill all required fields before proceeding.");
                    return;
                }
            }
    
            if (activeKey === "2") {
                const tripDays = parseInt(customDetails.tripDays) || 1;
                const minSelections = tripDays;
                
                if (!customDetails?.destination || customDetails.destination.length < minSelections) {
                    message.error(`You must select at least ${minSelections} destinations for a ${tripDays}-day trip.`);
                    return;
                }
            }
        }
    
        setActiveKey(nextKey);
    };

    useEffect(() => {
        console.log("choose experience", customDetails);
    }, [customDetails]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 992) {
                setTabPosition("top");
            } else {
                setTabPosition("left");
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const tabContents = [
        {
            key: "1",
            label: "Choose Your Experience",
            component: (
                <ChooseExperience
                    setCustomDetails={setCustomDetails}
                    setActiveKey={setActiveKey}
                    customDetails={customDetails}
                />
            ),
        },
        {
            key: "2",
            label: "Select Destinations",
            component: (
                <SelectDestinations
                    setCustomDetails={setCustomDetails}
                    setActiveKey={setActiveKey}
                    customDetails={customDetails}
                />
            ),
        },
        {
            key: "3",
            label: "Stay Options",
            component: (
                <StayOptions
                    setCustomDetails={setCustomDetails}
                    setActiveKey={setActiveKey}
                    customDetails={customDetails}
                />
            ),
        },
        {
            key: "4",
            label: "Food Preferences",
            component: (
                <FoodPreferences
                    setCustomDetails={setCustomDetails}
                    setActiveKey={setActiveKey}
                    customDetails={customDetails}
                />
            ),
        },
        {
            key: "5",
            label: "Transportation",
            component: (
                <Transportation
                    setCustomDetails={setCustomDetails}
                    setActiveKey={setActiveKey}
                    customDetails={customDetails}
                />
            ),
        },
        {
            key: "6",
            label: "Plan Your Itinerary",
            component: (
                <PlanYourItinerary 
                    setCustomDetails={setCustomDetails}
                    setActiveKey={setActiveKey}
                    customDetails={customDetails}
                />
            ),
        },
        {
            key: "7",
            label: "Additional Services",
            component: (
                <AdditionalServices
                    setCustomDetails={setCustomDetails}
                    setActiveKey={setActiveKey}
                    customDetails={customDetails}
                />
            ),
        },
    ];

    return (
        <>
            <Container className="py-5">
                <Row className="d-flex align-items-center justify-content-center">
                    <Col
                        xxl={8}
                        xl={8}
                        lg={10}
                        md={10}
                        sm={12}
                        xs={12}
                        className="text-center"
                    >
                        <p className="fs-16 fw-400 text-black">Customize</p>
                        <p
                            className={`${
                                window?.innerWidth > 834.98
                                ? "fs-48"
                                : window?.innerWidth <= 425
                                ? "fs-32"
                                : "fs-44"
                            } fw-800 gradient-text`}
                        >
                            Custom experience booking
                        </p>
                        <p
                            className={`${
                                window?.innerWidth > 834.98
                                ? "fs-18"
                                : window?.innerWidth <= 425
                                ? "fs-16"
                                : "fs-18"
                            } fw-400 text-black`}
                        >
                            Create Your Perfect Adventure at Joy n Joyy – Custom Day Plans,
                            Destinations & More!
                        </p>
                    </Col>
                </Row>

                <div className="my-5 custom-content-bg">
                    <Row className="d-flex justify-content-center m-1">
                        <p className="fs-20 fw-700 mb-0 tab-heading-mob">
                            Filter your tour
                        </p>

                        <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
                            <div
                                className="tab-labels-scroll-container"
                                style={{
                                maxWidth: "100%",
                                height: "100%",
                                overflowX: "auto",
                                padding: "5px",
                                borderRadius: "24px",
                                background: "#FFFFFF",
                                // scrollbarWidth: 'thin',
                                display: "flex",
                                flexDirection: tabPosition === "left" ? "column" : "row",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: tabPosition === "left" ? "column" : "row",
                                        gap: "14px",
                                        flexShrink: 0,
                                    }}
                                >
                                <p className="fs-20 fw-700 mb-0 tab-heading-web">
                                    Filter your tour
                                </p>
                                    {tabContents.map((tab) => (
                                        <div
                                            key={tab.key}
                                            onClick={() => handleTabChange(tab.key)}
                                            className={`custom-tab-label ${
                                                activeKey === tab.key ? "active" : ""
                                            } my-1`}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <span className="tab-key-circle">{tab.key}</span>
                                            <span className="tab-label-name">{tab.label}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>

                        <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
                            <div
                                className="tab-content-container"
                                style={{
                                background: "#FFFFFF",
                                borderRadius: "24px",
                                minHeight: "600px",
                                }}
                            >
                                {tabContents.find((tab) => tab.key === activeKey)?.component}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>

            <NewsAndUpdates />
        </>
    );
};

export default CustomBookingPage;
