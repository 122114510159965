import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import SignupBg from '../../assets/images/signup/signupbg.png';
import OtpVerify from '../../components/GroupBooking/OtpVerify/OtpVerify';

const OtpVerifyPage = () => {
    useEffect(() => {
        const footer = document.querySelector('footer');
        if (footer) {
          footer.style.display = 'none';
        }
    
        return () => {
          if (footer) {
            footer.style.display = '';
          }
        };
    }, []);
    
    useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
    }, []);
    
    return (
        <Container 
            fluid={true} 
            className="m-0" 
            style={{ 
                backgroundImage: `url(${SignupBg})`, 
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                minHeight: '100vh' 
            }}
        >
            <div 
                style={{
                    position: 'absolute',
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#00000066',
                    zIndex: 1
                }}
            />
            <div style={{ position: 'relative', zIndex: 2 }}>
                <OtpVerify />
            </div>
        </Container>
    );
}

export default OtpVerifyPage
