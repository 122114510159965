import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Button } from 'antd'
import failedAnimation from '../../../common/failedanimation.json'
import Lottie from 'lottie-react'
import './PaymentFail.css'

const PaymentFail = () => {
  return (
    <div>
      <video
        className="banner-wrapper-failure"
        autoPlay
        loop
        muted
        playsInline
        disablePictureInPicture
        controls={false}
        controlsList="nodownload noplaybackrate nofullscreen"
      >
        <source 
          src={require('../../../assets/videos/headervideo/header-video.mp4')} 
          type="video/mp4"
        />
      </video>

      <div className="gradient-overlay-failure">
        <Container className='py-5'>
            <Row className='d-flex align-items-center justify-content-center pb-5'>
                <Col xxl={5} xl={5} lg={8} md={10} sm={10} xs={10} className='text-center'>
                  <div style={{ zIndex: '1', position: 'relative' }}>
                    <div className="failed-animation">
                      <Lottie animationData={failedAnimation} loop={true} />
                    </div>

                    <p className={`${window?.innerWidth > 834.98 ? 'fs-36' : window?.innerWidth <= 425 ? 'fs-32' : 'fs-44'} fw-700 text-black`}>Payment Failed</p>
                    <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>Please try again or use a different payment method.</p>

                    <Button
                      className="fs-16 fw-700 booking-btn"
                      size="large"
                      style={{ background: 'black', color: 'white'}}
                    >
                      Try again
                    </Button>
                  </div>
                </Col>
            </Row>
        </Container>
      </div>
    </div>
  )
}

export default PaymentFail
