import React, { useEffect, useState } from 'react';
import { Col, Row } from "react-bootstrap";
import { Button, Image } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import './FoodPreferences.css';
import SouthIndian from "../../../assets/images/custom-booking/foodpreferences/img-south-indian.webp";
import NorthIndian from "../../../assets/images/custom-booking/foodpreferences/img-north-indian.webp";
import Continental from "../../../assets/images/custom-booking/foodpreferences/img-continental.webp";
import SeaFood from "../../../assets/images/custom-booking/foodpreferences/img-seafood-spl.webp";
import ChickenSpl from "../../../assets/images/custom-booking/foodpreferences/img-chicken-spl.webp";
import MuttonMasala from "../../../assets/images/custom-booking/foodpreferences/img-premium-homestay-1.webp";
import Meal from "../../../assets/images/custom-booking/foodpreferences/img-meal-per-day.webp";
import FullPackage from "../../../assets/images/custom-booking/foodpreferences/img-full-day-package.webp";

const FoodMenu = [
    {
        id: "Vegetarian",
        category: "Vegetarian Options",
        items: [
            { 
                id: 1, 
                menuimg: SouthIndian, 
                foodName: "South Indian Cuisine",
                description: "Dosa, Idli, Sambar, Rice, Roti, Curry, and Coastal Veg Specials."
            },
            {
                id: 2, 
                menuimg: NorthIndian, 
                foodName: "North Indian Cuisine",
                description: "Roti, Paneer dishes, Dal, Chole Bhature, Rice, Biryani."
            },
            { 
                id: 3, 
                menuimg: Continental, 
                foodName: "Traditional Homefoods",
                description: "Pasta, Salads, Sandwiches."
            },
        ],
    },
    {
        id: "Non-Vegetarian",
        category: "Non-Vegetarian Options",
        items: [
            { 
                id: 4, 
                menuimg: SeaFood, 
                foodName: "Seafood Special",
                description: "Fish Curry, Grilled Fish, Prawn dishes, and Coastal Delicacies."
            },
            { 
                id: 5, 
                menuimg: ChickenSpl, 
                foodName: "Chicken Specials",
                description: "Chicken Biryani, Grilled Chicken, Butter Chicken, Tandoori."
            },
            { 
                id: 6, 
                menuimg: MuttonMasala, 
                foodName: "Mutton Dishes",
                description: "Mutton Biryani, Mutton Curry, Kebab."
            },
        ],
    },
    {
        id: "Meal-Plans",
        category: "Meal Plans",
        items: [
            { 
                id: 7, 
                menuimg: Meal, 
                foodName: "Meal Per Day",
                description: "₹300 for Veg | ₹500 for Non-Veg"
            },
            { 
                id: 8, 
                menuimg: FullPackage, 
                foodName: "Full-Day Package",
                description: "₹600 Veg | ₹1000 Non-Veg (includes breakfast, lunch, and dinner)"
            },
        ],
    },
];

const FoodPreferences = ({ setCustomDetails, setActiveKey, customDetails }) => {
    const [foodPrefer, setFoodPrefer] = useState(customDetails.food || []);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 530);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 530);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleClick = (id, foodName) => {
        setFoodPrefer((prev) =>
            prev.some(item => item.id === id) 
                ? prev.filter(item => item.id !== id)
                : [...prev, { id, foodName }]
        )
    };    

    useEffect(() => {
        setCustomDetails((prevDetails) => ({
            ...prevDetails,
            food: foodPrefer,
        }));
    }, [foodPrefer, setCustomDetails]);

    const handleContinue = () => {
        setActiveKey('5')
    }

    const handleBack = () => {
        setActiveKey('3')
    }

    return (
        <div className="p-2 custom-food-container">
            <h4 className="fs-24 fw-700 py-2">Food Preferences</h4>

            <Row>
                {FoodMenu.map((menu) => (
                    <Col
                        key={menu.id}
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="pb-4"
                    >
                        <h6 className="fs-18 fw-700">{menu.category}</h6>

                        <Row>
                            {menu.items.map((item) => (
                                <Col
                                    key={item.id}
                                    xxl={6}
                                    xl={6}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className="pb-3"
                                >
                                    <div
                                        className="p-3 d-flex gap-3 justify-content-between"
                                        style={{
                                            background: "#F8F8F9",
                                            borderRadius: "24px",
                                            border: foodPrefer.some(selectedItem => selectedItem.id === item.id)
                                                ? "2px solid #000000"
                                                : "1px solid transparent",
                                        }}
                                        onClick={() => handleClick(item.id, item.foodName)}
                                    >
                                        <div className="d-flex flex-column">
                                            <CheckCircleFilled
                                                className="check-circle"
                                                style={{
                                                    color: foodPrefer.some(selectedItem => selectedItem.id === item.id)
                                                        ? "#2263E3"
                                                        : "transparent",
                                                    border: foodPrefer.some(selectedItem => selectedItem.id === item.id)
                                                        ? "1px solid transparent"
                                                        : "1px solid #303136",
                                                }}
                                            />
                                            <p className="fs-14 fw-700 destination-name my-2">
                                                {item.foodName}
                                            </p>
                                            <p className="fs-14 fw-400">{item.description}</p>
                                        </div>

                                        <div className="food-image-container">
                                            <Image
                                                src={item.menuimg}
                                                alt={item.foodName}
                                                preview={false}
                                                className="select-food-img"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                ))}
            </Row>

            <Row className="d-flex justify-content-end pt-4">
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="text-end">
                    <Button
                        type="default"
                        className="fs-14 fw-600 custom-back-btn m-1"
                        size="large"
                        style={{ borderColor: "#272727" }}
                        onClick={handleBack}
                    >
                        Back
                    </Button>

                    <Button
                        type="primary"
                        className="fs-14 fw-600 custom-book-btn m-1"
                        size="large"
                        onClick={handleContinue}
                    >
                        Continue
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default FoodPreferences;
