import React from 'react'
import { Container } from 'react-bootstrap'

const SentRequest = () => {
  return (
    <Container fluid={true} className="m-0" style={{ backgroundColor: '#F9FAFD',}}>
        
    </Container>
  )
}

export default SentRequest
