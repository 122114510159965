import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import DestiSlider from '../../components/TopDestiBooking/DestiSlider/DestiSlider'
import DestiTestimonial from '../../components/TopDestiBooking/DestiTestimonial/DestiTestimonial'
import NewsAndUpdates from '../../components/homepage/NewsAndUpdates/NewsAndUpdates'
import DestiBooking from '../../components/TopDestiBooking/DestiBooking/DestiBooking'
import DestiBookingBanner from '../../components/TopDestiBooking/DestiBookingBanner/DestiBookingBanner'

const TopDestBooking = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  return (
    <Container fluid={true} className="p-0">
      <DestiBookingBanner />
      <DestiSlider/>
      <DestiBooking/>
      <DestiTestimonial/>
      <NewsAndUpdates/>
    </Container>
  )
}

export default TopDestBooking
