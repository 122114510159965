import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Button } from 'antd'

const BookingBanner = () => {
    return (
        <div>
            <video
                className='banner-wrapper'
                autoPlay
                loop
                muted
                playsInline
                controls={false}
                disablePictureInPicture
                controlsList="nodownload noplaybackrate nofullscreen"
            >
                <source 
                    src={require('../../../assets/videos/headervideo/header-video.mp4')} 
                    type="video/mp4"
                />
            </video>
      
            <div className="gradient-overlay">
                <Container className="py-5" >
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xxl={7} xl={7} lg={9} md={10} sm={12} xs={12} className="text-center">
                            <p className="fs-28 fw-400 sub-header text-black mb-1">
                                Attractive Offers & Tailored Packages  
                            </p>
                            <p className='fw-400 header'>
                                Splash into Savings JoynJoyy Grand Opening Specials!
                            </p>
                            <p className='sub-heading-text fw-400 text-black'>
                                First 100 Tickets: 80% OFF
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default BookingBanner
