import { createContext, useContext, useState } from "react";

const BookingDataContext = createContext();

export const BookingDataProvider = ({ children }) => {
    const [bookingData, setBookingData] = useState({
        bookingDate: null,
        updatedBookingDetails: {},
        updatedFoodDetails: {},
        updatedMerchandise: {},
        updatedBillingDetails: {},
    });

    return (
        <BookingDataContext.Provider value={{ bookingData, setBookingData }}>
            {children}
        </BookingDataContext.Provider>
    );
};

export const useBookingData = () => useContext(BookingDataContext);
