import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Button } from 'antd'
import './OfferPageBanner.css'

const OfferPageBanner = ({ selectedCategory, setSelectedCategory }) => {
    
    const categories = [
        { key: "all", name: "All" },
        { key: "InaugurationOffers", name: "Inaugural Offer" },
        { key: "LocalResidents", name: "Regional Visitors" },
        { key: "School", name: "School" },
        { key: "College", name: "College" },
        { key: "Bulkbooking", name: "Bulk Booking" },
        { key: "Corporate", name: "Corporates" },
        { key: "Weekdays", name: "Weekday Offers" },
    ];
    return (        
        <div>
            <video
                className="banner-wrapper"
                autoPlay
                loop
                muted
                playsInline
                controls={false}
                disablePictureInPicture
                controlsList="nodownload noplaybackrate nofullscreen"
            >
                <source 
                    src={require('../../../assets/videos/headervideo/header-video.mp4')} 
                    type="video/mp4"
                />
            </video>

            <div className="gradient-overlay">
                <Container fluid={false} className='py-5'>
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xxl={8} xl={8} lg={10} md={10} sm={12} xs={12} className="text-center">
                            <p className="fs-28 fw-400 sub-header text-black mb-1">
                                Unlock Joy: Exclusive Offers Await!
                            </p>
                            <p className='fw-400 header'>
                                Incredible discounts tailored for everyone!
                            </p>
                            <p className='sub-heading-text fw-400 text-black'>
                                Individuals, groups, families, schools, organizations, and early birds. 
                                Don’t miss the chance to dive into the fun with unbeatable deals. 
                                Explore, book, and save today!
                            </p>
                        </Col>
                    </Row>

                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='text-center px-0'>
                            {categories.map((category) => (
                                <Button
                                    key={category.key}
                                    className="fs-16 fw-700 m-1 booking-btn"
                                    size="large"
                                    style={
                                        selectedCategory === category.key
                                            ? { background: 'black', color: 'white', borderColor: '#272727' }
                                            : { borderColor: '#272727' }
                                    }
                                    onClick={() => setSelectedCategory(category.key)}
                                >
                                    {category.name}
                                </Button>
                            ))}
                        </Col>
                    </Row>           
                </Container>
            </div>
        </div> 
    )
}

export default OfferPageBanner;