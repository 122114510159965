import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Image } from 'antd';
import './destibooking.css';
import agesIcon from '../../../assets/images/topdestination/destibooking-icon/destiicon1.svg'; 
import peopleIcon from '../../../assets/images/topdestination/destibooking-icon/destiicon2.svg'; 
import durationIcon from '../../../assets/images/topdestination/destibooking-icon/destiicon3.svg'; 
import checkAvailabilityIcon from '../../../assets/images/topdestination/destibooking-icon/destiicon4.svg'; 
import liveGuideIcon from '../../../assets/images/topdestination/destibooking-icon/destiicon5.svg';
import destinations from "../../../common/Destination.json";
import { Collapse } from 'antd';
import { useParams } from 'react-router-dom';

const { Panel } = Collapse;

const iconData = [
    { icon: agesIcon, title: 'AGES', description: 'All Age Groups' },
    { icon: peopleIcon, title: 'NO OF PEOPLE', description: 'max of 15 per group' },
    { icon: durationIcon, title: 'DURATION', description: 'NA' },
    { icon: checkAvailabilityIcon, title: 'START TIME', description: 'Check availability' },
    { icon: liveGuideIcon, title: 'LIVE GUIDE', description: 'TOUR GUIDE: Kan / Eng / Hin' },
];

const DestiBooking = () => {
    const [destination, setDestination] = useState(null);
    const { id } = useParams();
    
    useEffect(() => {
        const selectedDestination = destinations.find((dest) => dest.id === parseInt(id));
        setDestination(selectedDestination);
    }, [id]);

    if (!destination) return <p>Loading...</p>;

    return (
        <Container 
            fluid={true}
            className='p-0'
        >
            <div
                className='py-3 mt-4'
                style={{background: '#DBEBFE'}}
            >
            <Container>
                <Row className="icons-row">
                    {iconData.map((item, index) => (
                        <Col key={index} xxl={2} xl={2} lg={4} md={4} sm={12} xs={12}>
                            <Row className="d-flex align-items-center justify-content-center py-3">
                                <Col xxl={4} xl={4} lg={12} md={12} sm={3} xs={3} className='pe-3'>
                                    <Image 
                                        src={item.icon} 
                                        alt={item.title}
                                        className='booking-icons'
                                        style={{ width: 64, height: 64 }}
                                        preview={false}
                                    />
                                </Col>
                                <Col xxl={8} xl={8} lg={12} md={12} sm={9} xs={9}>
                                    <div className="text-icon">
                                        <p className="fs-14 fw-600 mb-1">{item.title}</p>
                                        <p className="fs-16 fw-700 text-primary m-0">{item.description}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    ))}
                </Row>
            </Container>
            </div>

            <Container>
                <Row className='py-5'>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="content-section">
                        <Collapse expandIconPosition="right">
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">About the Location</h5>} key="1" showArrow>
                                <p className="text-black fs-16 fw-400 destbook-about-content" onclick="toggleContent(this)">
                                    {destination.about}
                                </p>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Distance from WaterPark</h5>} key="2" showArrow>
                                <p className="text-black fs-16 fw-400 destbook-about-content" onclick="toggleContent(this)">
                                    {destination.distance}
                                </p>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Attractions</h5>} key="3" showArrow>
                            <div>
                                <ul className="text-black fs-16 fw-400 include-content">
                                    {destination.attractions.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Do's and Dont's</h5>} key="4" showArrow>
                            <div>
                                <ul className="text-black fs-16 fw-400 include-content">
                                    {destination.dosdont.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0 ">What’s Included</h5>} key="5" showArrow>
                            <div>
                                <ul className="text-black fs-16 fw-400 include-content">
                                    {destination.whatsIncluded.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">What’s not Included</h5>} key="6" showArrow>
                            <div>
                                <ul className="text-black fs-16 fw-400">
                                    {destination.notIncluded.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Customizing Your Visit</h5>} key="7" showArrow>
                                <p className="text-black fs-16 fw-400 destbook-about-content" onclick="toggleContent(this)">
                                    {destination.customizing}
                                </p>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Additional Information</h5>} key="8" showArrow>
                            <div>
                                <ul className="text-black fs-16 fw-400">
                                    {destination.additional.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Cancellation Policy</h5>} key="9" showArrow>
                                <div>
                                    <p className="text-black fs-16 fw-400">
                                        {destination.cancellationPolicy}
                                    </p>
                                </div>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Dedicated helpline</h5>} key="10" showArrow>
                                <div>
                                    <p className="text-black fs-16 fw-400">
                                        {destination.helpline}
                                    </p>
                                </div>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default DestiBooking;
