import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import PaymentSuccessSec from '../../components/Payment/PaymentSuccessSec/PaymentSuccessSec'
import FooterImage from '../../components/FooterImage/FooterImage'
import FrequentlyQues from '../../components/GuestFacilities/FrequentlyQues/FrequentlyQues'
import NewsAndUpdates from '../../components/homepage/NewsAndUpdates/NewsAndUpdates'

const PaymentSuccess = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  return (
    <Container fluid={true} className="p-0" >
      <PaymentSuccessSec />     
      <FrequentlyQues />
      <NewsAndUpdates />
    </Container>
  )
}

export default PaymentSuccess
